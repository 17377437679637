import React from 'react';
import { TiDocumentText } from 'react-icons/ti';
import PropTypes from 'prop-types';
import TextBlock from '../objects/TextBlock';

const About = ({ heading, shHeading, textBlock }) => (
  <div className="about-wrapper">
    <div className="container">
      <div className="row row--35 align-items-center">
        <div className="col-lg-12 col-md-12 section-title">
          <div className="about-inner inner">
            <div className="section-title text-center">
              <div className="icon">
                <TiDocumentText />
              </div>
              <h3 className="title mt--20 mb--30">{shHeading}</h3>
              <h5 className="description">{heading}</h5>
            </div>
            {/* Start tbOne */}
            <TextBlock cssClass="description" textBlock={textBlock} />
            {/* End tbOne */}
          </div>
        </div>
      </div>
    </div>
  </div>
);

About.propTypes = {
  heading: PropTypes.string,
  shHeading: PropTypes.string,
  textBlock: PropTypes.string,
};

export default About;
