import React, { memo } from 'react';
import PropTypes from 'prop-types';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from 'react-icons/fi';

import PageHelmet from './common/Helmet';
import Header from './common/Header';
import Breadcrumb from './common/Breadcrumb';
import FooterTwo from './common/FooterTwo';
import Map from './objects/Map';

const Methodology = ({ about }) => {
  const backgroundSection = 'About';
  const section = 'Methodology';

  if (!about || !about.methodology || about.methodology.length === 0) {
    return null;
  }

  return (
    <>
      <PageHelmet pageTitle={section} />
      <Header stickyNav={false} section={section} />
      <Breadcrumb backgroundSection={backgroundSection} title={section} />
      <Map mapDataArray={about.methodology} section={section} />
      <div className="backto-top">
        <ScrollToTop key="scroll-to-top-button" showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      <FooterTwo />
    </>
  );
};

Methodology.propTypes = {
  about: PropTypes.shape({
    methodology: PropTypes.array,
  }),
};

export default memo(Methodology);
