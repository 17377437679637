import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import slugify from 'slugify';
import { siteUrl } from '../../utils/constants';

const CasesList = ({ postList }) => {
  const getCasePath = (name) =>
    name ? `cases/${slugify(name.toLowerCase())}` : '#';

  const getImageUrl = (item, index) =>
    item?.attributes?.backgroundImage?.data?.attributes?.formats?.small?.url
      ? `${siteUrl}${item.attributes.backgroundImage.data.attributes.formats.small.url}`
      : `/assets/images/blog/blog-0${index + 1}.jpg`;

  return (
    <div className="row mt_dec--30" style={{ minHeight: '75vh' }}>
      {postList &&
        postList.map((item, index) => {
          const casePath = getCasePath(item?.attributes?.name);
          const imageUrl = getImageUrl(item, index);
          const itemName = item?.attributes?.name;

          return (
            <div
              className="col-lg-4 col-md-6 col-sm-6 col-12 mt--30"
              key={index}
            >
              <div className="im_box">
                <div className="thumbnail">
                  <Link to={casePath}>
                    <img className="w-100" src={imageUrl} alt="user Uploads" />
                  </Link>
                </div>
                <div className="content">
                  <div className="inner">
                    <div className="content_heading">
                      <div className="category_list">
                        <Link to={casePath}>CEM</Link>
                      </div>
                      <h4 className="title">
                        <Link to={casePath}>{itemName}</Link>
                      </h4>
                    </div>
                    <div className="content_footer">
                      <Link to={casePath} className="rn-btn btn-opacity">
                        {itemName ? 'Read more' : 'In Progress'}
                      </Link>
                    </div>
                  </div>
                  <Link to={casePath} className="transparent_link" />
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

CasesList.propTypes = {
  postList: PropTypes.array,
};

export default CasesList;
