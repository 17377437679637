import React from 'react';
import PropTypes from 'prop-types';

const ImgFullWidth = ({ caption }) => {
  const captionText =
    caption !== undefined && caption.substr('translation=') !== -1
      ? caption.split('translation=')
      : caption;

  return (
    <>
      {Array.isArray(captionText) ? (
        <div className="centered">
          <p className="mb--80">{captionText[0]}</p>
          <p>{captionText[1]}</p>
        </div>
      ) : (
        <h1 className="centered">{caption}</h1>
      )}
    </>
  );
};

ImgFullWidth.propTypes = {
  caption: PropTypes.string,
};

export default ImgFullWidth;
