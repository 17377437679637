import React from 'react';
import PropTypes from 'prop-types';

const ImgText = ({ captionArray, elementId = '', url }) => {
  if (url !== undefined && url !== '') {
    return (
      <div id={elementId} className="about-wrapper mb--40">
        <div className="container">
          <div className="row row--20 align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="thumbnail">
                <img className="w-100" src={url} alt="About Images" />
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="about-inner inner">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="about-us-list">
                      {captionArray.map((caption, index) => (
                        <p key={`${index}-img-text`}>{caption}</p>
                      ))}
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="about-us-list" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return '';
};

ImgText.propTypes = {
  captionArray: PropTypes.array,
  elementId: PropTypes.string,
  url: PropTypes.string,
};

export default ImgText;
