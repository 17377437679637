import React from 'react';
import PropTypes from 'prop-types';
import { FaFacebookF, FaLinkedinIn, FaTwitter } from 'react-icons/fa';

import { siteUrl } from '../../utils/constants';

const socialMediaIcons = {
  facebook: FaFacebookF,
  linkedin: FaLinkedinIn,
  twitter: FaTwitter,
};

const getSocialMediaIcon = (url) => {
  const socialMedia = Object.keys(socialMediaIcons).find((key) =>
    url.includes(key)
  );
  return socialMedia ? socialMediaIcons[socialMedia] : null;
};

const TeamMembers = ({ about }) => (
  <div className="row">
    {about?.teamMember &&
      about?.teamMember.map((member, memberId) => (
        <div
          key={`teamMember${memberId}`}
          className="col-lg-4 col-md-6 col-sm-6 col-12"
        >
          <div className="team">
            <a href={member?.url} target="_blank" rel="noreferrer">
              <div className="thumbnail">
                {member?.image && (
                  <img
                    className="w-100"
                    src={
                      siteUrl +
                      member?.image?.data?.attributes?.formats?.small?.url
                    }
                    alt="User Uploads"
                    loading="lazy"
                  />
                )}
              </div>
            </a>

            <div className="content">
              <a href={member?.url} target="_blank" rel="noreferrer">
                <h4 className="title">{member?.name}</h4>
                <p className="designation">{member?.role}</p>
              </a>
            </div>

            <ul className="social-icon">
              {member?.socialMedia &&
                member?.socialMedia.map((media, mediaIndex) => {
                  const Icon = getSocialMediaIcon(media?.url);

                  return Icon ? (
                    <li key={`teamSocialMedia${mediaIndex}`}>
                      <a
                        href={media?.url}
                        target="_blank"
                        rel="noreferrer"
                        aria-label={`${Icon.displayName} social media`}
                      >
                        <Icon />
                      </a>
                    </li>
                  ) : null;
                })}
            </ul>
          </div>
        </div>
      ))}
  </div>
);

TeamMembers.propTypes = {
  about: PropTypes.object,
};

export default TeamMembers;
