import React, { useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Scrollama, Step } from 'react-scrollama';
import PropTypes from 'prop-types';

import ImgFullScreen from './ImgFullScreen';

// Utils
import { imgSize } from '../../utils/funcs';

const ImagesMovingText = ({ images }) => {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  // This callback fires when a Step hits the offset threshold. It receives the
  // data prop of the step, which in this demo stores the index of the step.
  const onStepEnter = ({ data }) => {
    setCurrentStepIndex(data);
  };

  return (
    <Scrollama onStepEnter={onStepEnter} offset={0.5}>
      {images.map((image, stepIndex) => {
        const caption = image?.attributes?.caption || '';
        const captionArray = caption.includes('translation=')
          ? caption.split('translation=')
          : [caption];

        return (
          <Step data={stepIndex} key={stepIndex}>
            <div
              style={{
                backgroundImage: `url(${
                  currentStepIndex !== 0
                    ? imgSize(images[stepIndex], isMobileOnly)
                    : imgSize(images[stepIndex], isMobileOnly)
                })`,
                height: `${
                  stepIndex === 0 && image?.attributes?.caption !== ''
                    ? '200vh'
                    : '100vh'
                }`,
                backgroundAttachment: `${isMobileOnly ? 'scroll' : 'fixed'}`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
              }}
            >
              <ImgFullScreen
                captionArray={captionArray}
                url={imgSize(
                  images[isMobileOnly ? stepIndex : currentStepIndex],
                  isMobileOnly
                )}
                isMobileOnly={isMobileOnly}
                imgHeight={
                  stepIndex === 0 && caption !== '' ? '200vh' : '100vh'
                }
                imgMarginTop="0"
              />
            </div>
          </Step>
        );
      })}
    </Scrollama>
  );
};

ImagesMovingText.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      attributes: PropTypes.shape({
        caption: PropTypes.string,
      }),
    })
  ).isRequired,
};

export default ImagesMovingText;
