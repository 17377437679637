import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import PropTypes from 'prop-types';
import { siteUrl } from '../utils/constants';

const Testimonial = ({ sponsors }) => (
  <div className="rn-testimonial-area bg_color--5 ptb--120">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="section-title text-center mb--25">
            <span className="subtitle">
              {sponsors !== undefined ? sponsors.superHeadingTwo : ''}
            </span>
            <h3 className="title mb--50">
              {sponsors !== undefined ? sponsors.headingTwo : ''}
            </h3>
          </div>
          <Tabs>
            <div className="row align-items-center">
              <div className="col-lg-6 mt_md--40 mt_sm--40">
                <TabList className="testimonial-thumb-wrapper">
                  {sponsors !== undefined && sponsors.sponsors !== undefined ? (
                    sponsors.sponsors.data.map((sponsor, index) => (
                      <Tab key={`tab${index}`}>
                        <div className="testimonial-thumbnai">
                          <div className="thumb">
                            {sponsor.attributes !== null &&
                            sponsor.attributes !== undefined ? (
                              <img
                                src={
                                  siteUrl +
                                  sponsor.attributes.formats.thumbnail.url
                                }
                                alt={`Cultural Ecologies sponsor no. ${index}`}
                              />
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </Tab>
                    ))
                  ) : (
                    <Tab />
                  )}
                </TabList>
              </div>
              <div className="col-lg-6">
                {sponsors !== undefined &&
                sponsors.image !== '' &&
                sponsors.image !== undefined ? (
                  sponsors.image.map((sponsor, index) => (
                    <TabPanel key={`tabPanel${index}`}>
                      <div className="rn-testimonial-content text-left">
                        <div>
                          {sponsor.caption
                            .split('\n\n')
                            .map((paragraph, subIndex) => (
                              <p
                                className="sponsorship"
                                key={`tabParagraph${subIndex}`}
                              >
                                {paragraph}
                              </p>
                            ))}
                        </div>
                      </div>
                    </TabPanel>
                  ))
                ) : (
                  <TabPanel />
                )}
              </div>
            </div>
          </Tabs>
        </div>
      </div>
    </div>
  </div>
);

Testimonial.propTypes = {
  sponsors: PropTypes.object,
};

export default Testimonial;
