import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FiX, FiMenu } from 'react-icons/fi';
import PropTypes from 'prop-types';
import { scrollToTop } from '../../utils/funcs';

const Header = ({ color = 'default-color', stickyNav = true }) => {
  useEffect(() => {
    const headerElement = document.querySelector('.header--fixed');

    const stickHeader = () => {
      const value = window.scrollY;
      if (value > 50 && stickyNav) {
        headerElement.classList.add('sticky');
      } else {
        headerElement.classList.remove('sticky');
      }
    };

    window.addEventListener('scroll', stickHeader);

    return () => {
      window.removeEventListener('scroll', stickHeader);
    };
  }, [stickyNav]);

  useEffect(() => {
    const elements = document.querySelectorAll('.has-droupdown > a');
    Object.entries(elements).forEach(([, value]) => {
      value.onclick = function () {
        value.parentElement
          .querySelector('.submenu')
          .classList.toggle('active');
        value.classList.toggle('open');
      };
    });
  }, []);

  const menuTrigger = () => {
    document.querySelector('.header-wrapper').classList.toggle('menu-open');
  };

  const closeMenuTrigger = () => {
    document.querySelector('.header-wrapper').classList.remove('menu-open');
  };

  const handleChange = () => {
    scrollToTop();
  };

  return (
    <header
      className={`header-area header--fixed formobile-menu header--transparent ${color}`}
    >
      <div className="header-wrapper" id="header-wrapper">
        <div className="header-left">
          <div className="header-btn">
            <Link
              className="btn-default btn-border btn-opacity size-lg"
              to="/cases"
              onClick={handleChange}
              onKeyDown={handleChange}
            >
              <span>CEM</span>
            </Link>
          </div>
        </div>
        <div className="header-right">
          <nav className="mainmenunav d-lg-block">
            <ul className="mainmenu">
              <li>
                <Link to="/">Home</Link>
              </li>

              <li className="has-droupdown">
                <Link
                  to="/about"
                  onClick={handleChange}
                  onKeyDown={handleChange}
                >
                  About
                </Link>
                <ul className="submenu">
                  <li>
                    <Link
                      to="/about/methodology"
                      onClick={handleChange}
                      onKeyDown={handleChange}
                    >
                      Methodology
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/about/author"
                      onClick={handleChange}
                      onKeyDown={handleChange}
                    >
                      About the author
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/about/contributors"
                      onClick={handleChange}
                      onKeyDown={handleChange}
                    >
                      Contributors
                    </Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link
                  to="/cases"
                  onClick={handleChange}
                  onKeyDown={handleChange}
                >
                  Cases
                </Link>
              </li>
              <li>
                <Link
                  to="/archive"
                  onClick={handleChange}
                  onKeyDown={handleChange}
                >
                  Archive
                </Link>
              </li>
              <li>
                <Link
                  to="/contact"
                  onClick={handleChange}
                  onKeyDown={handleChange}
                >
                  Contribute
                </Link>
              </li>
            </ul>
          </nav>

          {/* Start Hamburger Menu  */}
          <div className="humberger-menu d-block d-lg-none pl--20 pl_sm--10">
            <span
              onClick={menuTrigger}
              onKeyDown={menuTrigger}
              className="menutrigger text-white"
              role="button"
              tabIndex={0}
              aria-label="Hamburger menu"
            >
              <FiMenu />
            </span>
          </div>
          {/* End Hamburger Menu  */}
          <div className="close-menu d-block d-lg-none">
            <span
              onClick={closeMenuTrigger}
              onKeyDown={closeMenuTrigger}
              className="closeTrigger"
              role="button"
              tabIndex={0}
              aria-label="Closes menu trigger"
            >
              <FiX />
            </span>
          </div>
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  color: PropTypes.string,
  stickyNav: PropTypes.bool,
};

export default Header;
