import React from 'react';

import PropTypes from 'prop-types';
import RenderLoader from './common/RenderLoader';
import { siteUrl } from '../utils/constants';

const BrandTwo = ({ sponsors }) => (
  <div className="rn-brand-area bg_color--1 ptb--120">
    <div className="container">
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="section-title text-center mb--30">
            <span className="subtitle">
              {sponsors.attributes !== undefined &&
              sponsors.attributes.superHeadingOne !== undefined
                ? sponsors.attributes.superHeadingOne
                : ''}
            </span>
            <h3 className="title">
              {sponsors.attributes !== undefined &&
              sponsors.attributes.headingOne !== undefined
                ? sponsors.attributes.headingOne
                : ''}
            </h3>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <ul className="brand-style-2">
            {sponsors.attributes !== undefined &&
            sponsors.attributes.image !== undefined &&
            sponsors.attributes.image !== null ? (
              sponsors.attributes.sponsors.data.map((item, index) => (
                <li key={`sponsors${index}`}>
                  <img
                    src={`${siteUrl}${item.attributes.formats.thumbnail.url}`}
                    alt={`Cultural Ecologies sponsor no.${index}`}
                  />
                </li>
              ))
            ) : (
              <RenderLoader />
            )}
          </ul>
        </div>
      </div>
    </div>
  </div>
);

BrandTwo.propTypes = {
  sponsors: PropTypes.object,
};

export default BrandTwo;
