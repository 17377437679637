/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {
  Autoplay,
  EffectFade,
  Navigation,
  Pagination,
} from 'swiper/core';

// Import Swiper styles
import 'swiper/swiper.min.css';
import 'swiper/components/effect-fade/effect-fade.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/navigation/navigation.min.css';

import PropTypes from 'prop-types';
import { siteUrl } from '../../utils/constants';

// install Swiper modules
SwiperCore.use([Autoplay, EffectFade, Pagination, Navigation]);

const ImagesCarousel = ({ images, cssClass = 'mb--40', toTop }) => {
  const [status, setStatus] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  if (isOpen && status) {
    setStatus(0);
  }

  if (toTop && status) {
    setStatus(0);
    setStatus(0);
  }

  const changeStatusPhotoIndex = (value) => {
    if (value >= 0) {
      setPhotoIndex(value);
      setIsOpen(true);
    }
  };

  const imagesList =
    images && Array.isArray(images) && isMobileOnly
      ? images.map((item) =>
          Object.keys(item).length !== 0
            ? siteUrl + item.attributes.formats.medium.url
            : ''
        )
      : images && Array.isArray(images)
      ? images.map((item) => siteUrl + item.attributes.url)
      : '';

  const imagesCaptionList =
    images && Array.isArray(images)
      ? images.map((item) => item.attributes.caption)
      : '';

  return (
    <div className={`container  ${cssClass}`}>
      <div className="row">
        <div className="col-lg-12">
          <div className="inner-wrapper">
            <div className="inner">
              {images && Array.isArray(images) ? (
                <Swiper
                  spaceBetween={30}
                  autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  className="mySwiper"
                  effect="fade"
                  centeredSlides
                  grabCursor
                  loop
                >
                  {images.map((image, index) => (
                    <SwiperSlide key={`home-carousel${index}`}>
                      <a
                        role="button"
                        onClick={() => changeStatusPhotoIndex(index)}
                        onWheel={
                          status
                            ? () => changeStatusPhotoIndex(index)
                            : changeStatusPhotoIndex()
                        }
                        onMouseEnter={
                          status
                            ? () => changeStatusPhotoIndex(index)
                            : changeStatusPhotoIndex()
                        }
                        onKeyDown={() => changeStatusPhotoIndex(index)}
                        tabIndex={0}
                      >
                        {isMobileOnly &&
                        image?.attributes?.formats !== undefined ? (
                          <img
                            src={`${siteUrl}${image?.attributes?.formats.small.url}`}
                            alt=""
                          />
                        ) : (
                          <img
                            src={`${siteUrl}${image?.attributes?.url}`}
                            alt=""
                          />
                        )}
                      </a>
                    </SwiperSlide>
                  ))}
                  {isOpen && (
                    <Lightbox
                      mainSrc={imagesList[photoIndex]}
                      nextSrc={imagesList[(photoIndex + 1) % imagesList.length]}
                      prevSrc={
                        imagesList[
                          (photoIndex + imagesList.length - 1) %
                            imagesList.length
                        ]
                      }
                      onCloseRequest={() => setIsOpen(false)}
                      onMovePrevRequest={() =>
                        setPhotoIndex(
                          (photoIndex + imagesList.length - 1) %
                            imagesList.length
                        )
                      }
                      onMoveNextRequest={() =>
                        setPhotoIndex((photoIndex + 1) % imagesList.length)
                      }
                      imageCaption={imagesCaptionList[photoIndex]}
                      enableZoom={false}
                    />
                  )}
                </Swiper>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ImagesCarousel.propTypes = {
  images: PropTypes.array,
  cssClass: PropTypes.string,
  toTop: PropTypes.bool,
};

export default ImagesCarousel;
