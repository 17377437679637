import React, { useContext, useState } from 'react';
import CacheBuster from 'react-cache-buster';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import packageJson from '../package.json';
import { DataProvider, DataContext } from './context/DataContext';
import './index.scss';
import Home from './elements/Home';
import About from './elements/About';
import Archive from './elements/Archive';
import ArchiveDetails from './elements/ArchiveDetails';
import Author from './elements/Author';
import Cases from './elements/Cases';
import CaseDetails from './elements/CaseDetails';
import Contact from './elements/Contact';
import Contributors from './elements/about/Contributors';
import Methodology from './elements/Methodology';
import error404 from './elements/error404';
import ErrorBoundary from './elements/common/ErrorBoundary';

const { version } = packageJson;

const App = () => {
  const {
    about,
    author,
    archiveCollectionItemId,
    archiveCollectionName,
    backgroundImages,
    cases,
    contact,
    contributors,
    documents,
    home,
    setArchiveCollectionName,
    sponsors,
    videos,
    videosVimeo,
  } = useContext(DataContext);

  const [redirectToArchive, setRedirectToArchive] = useState(false);
  const [redirectToCases, setRedirectToCases] = useState(false);

  return (
    <ErrorBoundary>
      <BrowserRouter basename="/">
        {redirectToArchive && <Redirect to="/archive" />}
        {redirectToCases && <Redirect to="/cases" />}
        <Switch>
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/`}
            render={() => <Home home={home} />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/about`}
            render={() => (
              <About
                about={about}
                backgroundImages={backgroundImages}
                sponsors={sponsors}
              />
            )}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/about/contributors`}
            render={() => (
              <Contributors contributors={contributors} sponsors={sponsors} />
            )}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/cases`}
            render={() => <Cases cases={cases} />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/cases/:urlslug`}
            render={() => (
              <CaseDetails setRedirectToCases={setRedirectToCases} />
            )}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/contact`}
            render={() => <Contact contact={contact} sponsors={sponsors} />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/about/author`}
            render={() => (
              <Author
                author={author}
                backgroundImages={backgroundImages}
                sponsors={sponsors}
              />
            )}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/about/methodology`}
            render={() => <Methodology about={about} />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/archive`}
            render={() => (
              <Archive
                backgroundImages={backgroundImages}
                documents={documents}
                sponsors={sponsors}
                videos={videos}
              />
            )}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/archive/:urlslug`}
            render={({ match }) => {
              const { urlslug } = match.params;
              return (
                <ArchiveDetails
                  archiveCollectionName={archiveCollectionName}
                  archiveCollectionItemId={archiveCollectionItemId}
                  documents={documents}
                  setArchiveCollectionName={setArchiveCollectionName}
                  setRedirectToArchive={setRedirectToArchive}
                  videos={videos}
                  videosVimeo={videosVimeo}
                  urlSlug={urlslug}
                />
              );
            }}
          />

          <Route path={`${process.env.PUBLIC_URL}/404`} component={error404} />
          <Route component={error404} />
        </Switch>
      </BrowserRouter>
    </ErrorBoundary>
  );
};

const root = createRoot(document.getElementById('root'));
root.render(
  <CacheBuster
    currentVersion={version}
    isEnabled
    isVerboseMode={false}
    metaFileDirectory="."
  >
    <DataProvider>
      <App />
    </DataProvider>
  </CacheBuster>
);
