export const extractProviderAndVideoId = (url) => {
  let videoId;
  let provider;

  // Check if it's a YouTube URL
  const youTubeRegex =
    /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([^&\n?]+)/;
  if (youTubeRegex.test(url)) {
    const [, youTubeVideoId] = url.match(youTubeRegex);
    videoId = youTubeVideoId;
    provider = 'youtube';
  }

  // Check if it's a Vimeo URL
  const vimeoRegex = /(?:vimeo\.com\/)([^&\n?]+)/;
  if (vimeoRegex.test(url)) {
    const [, vimeoVideoId] = url.match(vimeoRegex);
    videoId = vimeoVideoId;
    provider = 'vimeo';
  }

  // Return an object with the provider and video ID
  return { provider, videoId };
};
