import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import ImgCaption from './ImgCaption';

import { getImageDimensions } from '../../utils/getImageDimensions';

const ImgFullWidth = ({
  caption = '',
  cssClass = 'mb--100',
  elementId = '',
  fileUrl = '',
  url,
  type,
}) => {
  const [singleImgSize, setSingleImgSize] = useState('');
  useEffect(() => {
    async function fetchImageDimensions() {
      try {
        const dimensions = await getImageDimensions(url);
        if (dimensions.width < 1000 || type === 'standardSmall') {
          setSingleImgSize('small-centered-img');
        } else if (type === 'standardMedium') {
          setSingleImgSize('medium-centered-img');
        }
      } catch (error) {
        console.error('Error fetching image dimensions:', error);
      }
    }

    fetchImageDimensions();
  }, [url, type]);

  return (
    <div id={elementId} className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="inner-wrapper">
            <div className="inner">
              <div className={`thumbnail ${cssClass}`}>
                {fileUrl !== '' ? (
                  <a href={fileUrl} target="_blank" rel="noreferrer">
                    <img
                      src={`${url}`}
                      className={`mb--40 ${singleImgSize}`}
                      alt="User uploads"
                    />
                    <ImgCaption caption={caption} />
                  </a>
                ) : (
                  <>
                    <img
                      src={`${url}`}
                      className={`mb--40 ${singleImgSize}`}
                      alt="User uploads"
                    />
                    <ImgCaption caption={caption} />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ImgFullWidth.propTypes = {
  caption: PropTypes.string,
  cssClass: PropTypes.string,
  elementId: PropTypes.string,
  fileUrl: PropTypes.string,
  url: PropTypes.string,
  type: PropTypes.string,
};

export default ImgFullWidth;
