import React from 'react';
import PropTypes from 'prop-types';
import TextBlock from '../objects/TextBlock';

const AboutThree = ({ heading, imageUrl, text, superHeading }) => (
  <>
    <div className="about-wrapper">
      <div className="container">
        <div className="row row--35 align-items-center">
          <div className="col-lg-5 col-md-12">
            <div className="thumbnail">
              <img className="w-100" src={`${imageUrl}`} alt="About Images" />
            </div>
          </div>
          <div className="col-lg-7 col-md-12">
            <div className="about-inner inner">
              <div className="section-title">
                <div className="purchase-btn mt--30">
                  <span className="subtitle">{superHeading}</span>
                </div>
                <h3 className="title mt--20">{heading}</h3>
                <TextBlock textBlock={text} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
);

AboutThree.propTypes = {
  heading: PropTypes.string,
  imageUrl: PropTypes.string,
  text: PropTypes.string,
  superHeading: PropTypes.string,
};

export default AboutThree;
