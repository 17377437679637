import React, { useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import * as Scroll from 'react-scroll';
import PropTypes from 'prop-types';

const scroll = Scroll.animateScroll;

const BreadcrumbCases = ({
  bgCaseImg,
  subHeading,
  caseName,
  dataOverlay,
  toTop,
  setToTop,
}) => {
  const myInput = React.createRef();
  const [scrolled, setScrolled] = useState(0);

  const animateClass = () => {
    const chaptersDiv = document.querySelector('#chapters');
    const firstDiv = document.querySelector('#firstDiv');
    const secondDiv = document.querySelector('#secondDiv');

    if (toTop === 0 && !isMobileOnly && chaptersDiv && firstDiv && secondDiv) {
      // START from slideshow to chaptersDiv
      if (
        window.scrollY > 10 &&
        window.scrollY < chaptersDiv.offsetTop &&
        scrolled === 0
      ) {
        scroll.scrollTo(chaptersDiv.offsetTop, { smooth: true });
        setScrolled(1);
      }

      if (
        window.scrollY > 10 &&
        window.scrollY < chaptersDiv.offsetTop &&
        (scrolled === 2 || scrolled === 6)
      ) {
        scroll.scrollTo(0, { smooth: true });
        setScrolled(3);
      }
      // END from chaptersDiv to slideshow

      // START from chaptersDiv to firstDiv
      if (
        window.scrollY > chaptersDiv.offsetTop &&
        window.scrollY < firstDiv.offsetTop &&
        scrolled === 2
      ) {
        scroll.scrollTo(firstDiv.offsetTop, { smooth: true });
        setScrolled(4);
      }
      if (
        window.scrollY > chaptersDiv.offsetTop &&
        window.scrollY < firstDiv.offsetTop &&
        (scrolled === 5 || scrolled === 9)
      ) {
        scroll.scrollTo(chaptersDiv.offsetTop, { smooth: true });
        setScrolled(6);
      }
      // END from firstDiv to chaptersDiv

      // START from firstDiv to secondDiv
      if (window.scrollY > firstDiv.offsetTop && scrolled === 5) {
        scroll.scrollTo(secondDiv.offsetTop - 30, {
          smooth: true,
        });
        setScrolled(7);
      }

      if (
        window.scrollY > firstDiv.offsetTop &&
        window.scrollY < secondDiv.offsetTop - 30 &&
        scrolled === 8
      ) {
        scroll.scrollTo(firstDiv.offsetTop, { smooth: true });
        setScrolled(9);
      }
      // END from secondDiv to firstDiv

      // chaptersDiv beginning
      if (
        window.scrollY >= chaptersDiv.offsetTop &&
        window.scrollY < chaptersDiv.offsetTop + 1
      ) {
        setScrolled(2);
        setToTop(0);
      }

      // firstDiv beginning
      if (
        window.scrollY >= firstDiv.offsetTop &&
        window.scrollY < firstDiv.offsetTop + 1
      ) {
        setScrolled(5);
      }

      // secondDiv beginning
      if (window.scrollY >= secondDiv.offsetTop - 30) {
        setScrolled(8);
      }
    }
    if (window.scrollY < 10) {
      setScrolled(0);
      setToTop(0);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', animateClass);

    return () => {
      window.removeEventListener('scroll', animateClass);
    };
  });

  return (
    <div
      className="rn-page-title-area pt--120 pb--100 bg_image"
      data-black-overlay={dataOverlay}
      style={{
        backgroundImage: `url(${bgCaseImg})`,
        backgroundAttachment: `${isMobileOnly ? 'scroll' : 'fixed'}`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        filter: 'opacity(80%)',
      }}
      ref={myInput}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="breadcrumb-inner pt--100">
              <h1 className="title text-white">{caseName}</h1>
              <ul className="page-list">
                <li className="rn-breadcrumb-item active description">
                  {subHeading}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

BreadcrumbCases.propTypes = {
  bgCaseImg: PropTypes.string,
  subHeading: PropTypes.string,
  caseName: PropTypes.string,
  dataOverlay: PropTypes.number,
  toTop: PropTypes.number,
  setToTop: PropTypes.func,
};

export default BreadcrumbCases;
