import React from 'react';
import Vimeo from '@u-wave/react-vimeo';
import PropTypes from 'prop-types';

const VideoFullScreen = ({ elementId = '', url }) => (
  <div id={elementId} className="embed-responsive embed-responsive-16by9">
    {url && url !== '' ? (
      <Vimeo
        autoplay
        autopause={false}
        controls={false}
        height={1000}
        loop
        muted
        video={url}
      />
    ) : (
      ''
    )}
  </div>
);

VideoFullScreen.propTypes = {
  elementId: PropTypes.string,
  url: PropTypes.string,
};

export default VideoFullScreen;
