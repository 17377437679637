import React from 'react';

import TextUrl from './TextUrl';

const TextFootNote = ({ tbFootNote }) =>
  tbFootNote.map((content) => {
    if (content.indexOf(']{') !== -1) {
      const tbFn = content.split('}');

      return tbFn.map((tbContent, index) => {
        if (tbContent.indexOf(']{') !== -1 && content.indexOf('link=') === -1) {
          const hrefText = tbContent.split('{');

          return (
            <cite key={`textItalic${index}`} className="tooltip">
              {hrefText[0]}
              <span className="tooltiptext">{hrefText[1]}</span>
            </cite>
          );
        }

        if (tbContent.indexOf('link=') !== -1) {
          return (
            <TextUrl
              key={`textItalicsFn${index}`}
              tbLinks={tbContent.split('link=')}
            />
          );
        }

        return tbContent;
      });
    }

    if (content.indexOf('quote=') !== -1 || content.indexOf('*') !== -1) {
      return '';
    }

    return content;
  });

export default TextFootNote;
