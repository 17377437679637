import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';

const PageHelmet = ({ pageTitle }) => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>{pageTitle} || CEM</title>
      {/* <meta name="robots" content="noindex, follow" /> */}
      <meta
        name="description"
        content="Cultural Ecologies of Memory by Ricardo Velasco"
      />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
    </Helmet>
  </>
);

PageHelmet.propTypes = {
  pageTitle: PropTypes.string,
};

export default PageHelmet;
