import { siteUrl } from './constants';

export const convertToRoman = (num) => {
  const decimalValue = [1000, 900, 500, 400, 100, 90, 50, 40, 10, 9, 5, 4, 1];
  const romanNumeral = [
    'M',
    'CM',
    'D',
    'CD',
    'C',
    'XC',
    'L',
    'XL',
    'X',
    'IX',
    'V',
    'IV',
    'I',
  ];

  let romanized = '';
  let indexLimit = num;

  for (let index = 0; index < decimalValue.length; index += 1) {
    while (decimalValue[index] <= indexLimit) {
      romanized += romanNumeral[index];
      indexLimit -= decimalValue[index];
    }
  }

  return romanized;
};

export const imgSize = (container, isMobileOnly) => {
  if (container) {
    // temporary code
    if (container.img) {
      return siteUrl + container.img.url;
    }
    // temporary code
    if (container.created_at) {
      return siteUrl + container.url;
    }
    if (!isMobileOnly && container.image) {
      return siteUrl + container.image.data.attributes.url;
    }
    if (!isMobileOnly) {
      return siteUrl + container?.attributes?.url;
    }
    if (isMobileOnly && container.image) {
      return siteUrl + container.image.data.attributes.formats.medium.url;
    }
    if (isMobileOnly && container.bgImg) {
      return siteUrl + container.bgImg.formats.medium.url;
    }
    if (container.url && !isMobileOnly) {
      return siteUrl + container.url;
    }
    if (isMobileOnly && container.formats) {
      return siteUrl + container.formats.medium.url;
    }
  }

  return '';
};

// Scroll to top
export const scrollToTop = () => {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
};

// Local/Session Storage
export const getStorageItem = (key) => sessionStorage.getItem(key);
export const parseStorageItem = (key) => {
  let storageItem;
  try {
    storageItem = getStorageItem(key);
    if (!storageItem) {
      return null;
    }
    return JSON.parse(storageItem);
  } catch (error) {
    console.error(`Error parsing storage item '${key}':`, error, storageItem);
    return null;
  }
};
export const setStorageItem = (key, item) =>
  sessionStorage.setItem(key, JSON.stringify(item));
