import React, { useState, useEffect } from 'react';
import ModalVideo from 'react-modal-video';
import PropTypes from 'prop-types';

import { extractProviderAndVideoId } from '../../utils/extractProviderAndVideoId';

// Arrow function to disable scrolling
const disableScroll = () => {
  document.body.style.overflow = 'hidden';
};

// Arrow function to enable scrolling
const enableScroll = () => {
  document.body.style.overflow = 'auto';
};

const VideoStandard = ({
  elementId = '',
  cssClass,
  start = '',
  url,
  vidImg,
}) => {
  const [isOpen, setOpen] = useState(false);
  const trimmedUrl = url.trim();
  const videoDetails = extractProviderAndVideoId(trimmedUrl);

  useEffect(() => {
    if (isOpen) {
      disableScroll();
    } else {
      enableScroll();
    }

    // Cleanup function to restore scrolling when the component unmounts
    return () => {
      enableScroll(); // Restore scrolling
    };
  }, [isOpen]);

  return (
    <div id={elementId} className={`container ${cssClass} mb--50`}>
      <div className="row">
        <div className="col-lg-12">
          <div className="inner-wrapper">
            <div className="inner">
              <div className="embed-responsive-small video-wrapper position-relative mb--40">
                <div className="thumbnail">
                  <img
                    src={
                      vidImg ??
                      `https://img.youtube.com/vi/${videoDetails.videoId}/maxresdefault.jpg`
                    }
                    alt="user Uploads"
                  />
                </div>
                <ModalVideo
                  channel={videoDetails.provider}
                  isOpen={isOpen}
                  videoId={videoDetails.videoId + start}
                  onClose={() => setOpen(false)}
                />
                <button
                  type="button"
                  className="video-popup position-top-center"
                  onClick={() => setOpen(true)}
                  aria-label="Text Label"
                >
                  <span className="play-icon" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

VideoStandard.propTypes = {
  elementId: PropTypes.string,
  cssClass: PropTypes.string,
  start: PropTypes.string,
  url: PropTypes.string,
  vidImg: PropTypes.string,
};

export default VideoStandard;
