import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import PropTypes from 'prop-types';

const ImgFullScreen = ({
  captionArray,
  url,
  elementId = '',
  imgMarginTop = 'mb--40',
  imgHeight = '100vh',
}) => (
  <div
    id={elementId}
    className={`${imgMarginTop}`}
    data-black-overlay="2"
    style={
      url
        ? {
            backgroundImage: `url(${url})`,
            height: `${imgHeight}`,
            backgroundAttachment: `${isMobileOnly ? 'scroll' : 'fixed'}`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }
        : {}
    }
  >
    {Array.isArray(captionArray) && captionArray.length === 2 ? (
      <div className="centered">
        <p className="mb--80">{captionArray[0]} </p>
        <p>{captionArray[1]}</p>
      </div>
    ) : (
      ''
    )}

    {captionArray &&
      Array.isArray(captionArray) &&
      captionArray.length === 1 && (
        <h1 className="centered">{captionArray[0]} </h1>
      )}
  </div>
);

ImgFullScreen.propTypes = {
  captionArray: PropTypes.array,
  elementId: PropTypes.string,
  url: PropTypes.string,
  imgMarginTop: PropTypes.string,
  imgHeight: PropTypes.string,
};

export default ImgFullScreen;
