import React from 'react';
import { Link } from 'react-router-dom';
import { FaAngleRight } from 'react-icons/fa';
import PropTypes from 'prop-types';

const Pagination = ({ activePagTab, pagInt, setActivePagTab }) => {
  const lis = [];

  for (let index = 1; index <= pagInt; index += 1) {
    lis.push(
      <li
        key={`blogPagination${index}`}
        className={`${activePagTab === index ? 'active' : ''}`}
      >
        <Link
          to="#"
          onKeyDown={() => setActivePagTab(index)}
          onClick={() => setActivePagTab(index)}
        >
          {index}
        </Link>
      </li>
    );
  }

  return (
    <div className="rn-pagination text-center">
      <ul className="page-list">
        {lis}
        <li>
          <Link to="#">
            <FaAngleRight />
          </Link>
        </li>
      </ul>
    </div>
  );
};

Pagination.propTypes = {
  activePagTab: PropTypes.number,
  pagInt: PropTypes.number,
  setActivePagTab: PropTypes.func,
};

export default Pagination;
