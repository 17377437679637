import React from 'react';
import PropTypes from 'prop-types';

const DataItem = ({ heading, text }) => {
  if (text !== '') {
    return (
      <div className="port-view mt-xl-3 mb-xl-3">
        <h5>{heading}</h5>
        <span>{text}</span>
      </div>
    );
  }

  return '';
};

DataItem.propTypes = {
  heading: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default DataItem;
