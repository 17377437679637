import React from 'react';
import PropTypes from 'prop-types';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from 'react-icons/fi';
import { isMobileOnly } from 'react-device-detect';
import PageHelmet from '../common/Helmet';
import Header from '../common/Header';
import Breadcrumb from '../common/Breadcrumb';
import FooterTwo from '../common/FooterTwo';
import RenderLoader from '../common/RenderLoader';
import BrandTwo from '../BrandTwo';
import { imgSize } from '../../utils/funcs';
import TextBlockTwo from '../objects/TextBlockTwo';

const Contributor = ({ contributor }) => {
  const { name, title, biography, url } = contributor;
  const imageUrl = imgSize(contributor, isMobileOnly);

  return (
    <div className="col-md-6 mb-4 mt-xl-5">
      <div className="card p-3 rounded border-0">
        <div className="contributor-image mt-xl-4 mb-3 text-center">
          {contributor?.image?.data?.attributes && (
            <img
              src={imageUrl}
              alt={`${name}'s profile`}
              className="contributor-profile-image img-fluid rounded-circle"
            />
          )}
        </div>
        <div className="text-center">
          <h3 className="h5 mt-xl-4">{name}</h3>
          <h4 className="h6 text-muted">{title}</h4>
        </div>

        <div className="mt-xl-4">
          <TextBlockTwo content={biography} />
        </div>

        {url && Array.isArray(url) && url.length > 0 && (
          <div className="container">
            <div className="row">
              <div className="links mt-xl-4 mb-3">
                <div className="col-lg-12">
                  <div className="inner-wrapper">
                    <div className="inner">
                      <h5>Links:</h5>
                      {url.map((link, index) => (
                        <a
                          key={index}
                          href={link.url}
                          className="theme-gradient"
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          {link.url}
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const Contributors = ({ contributors, sponsors }) => {
  const section = 'About';

  return (
    <>
      <PageHelmet pageTitle={section} />
      <Header section={section} />
      <Breadcrumb backgroundSection={section} title={section} />

      {/* Start Contributors */}
      <div className="rn-team-area bg_color--5 ptb--120">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title mb--30 text-center">
                {contributors ? (
                  <>
                    <span className="subtitle">
                      {contributors?.superHeadingOne}
                    </span>
                    <h3 className="title mt--20 mb--50">
                      {contributors?.heading}
                    </h3>
                    {contributors?.text && (
                      <TextBlockTwo content={contributors?.text} />
                    )}
                  </>
                ) : (
                  <RenderLoader />
                )}
              </div>
            </div>
          </div>
          <div className="row">
            {contributors &&
              contributors?.contributor?.map((contributor) => (
                <Contributor key={contributor.id} contributor={contributor} />
              ))}
          </div>
        </div>
      </div>
      {/* End Contributors */}

      <BrandTwo sponsors={sponsors} />

      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>

      <FooterTwo />
    </>
  );
};

Contributor.propTypes = {
  contributor: PropTypes.object.isRequired,
};

Contributors.propTypes = {
  sponsors: PropTypes.object,
  contributors: PropTypes.object,
};

export default Contributors;
