export const getArchiveDetailFields = (datasheet) => [
  // 1
  { heading: 'Title', text: datasheet.title ?? null },
  {
    heading: datasheet?.description?.length > 120 ? '' : 'Description',
    text: datasheet?.description ?? null,
    colSize: 8,
  },
  { heading: '', text: datasheet.annotation ? ' ' : null },
  { heading: 'Annotation', text: datasheet.annotation ?? null, colSize: 8 },

  // 2
  {
    heading: 'Author',
    text: datasheet.author ?? null,
    colSize: datasheet?.subject?.length > 0 ? 4 : 12,
  },
  {
    heading: 'Subject',
    text: datasheet?.subject ?? null,
    colSize: 8,
  },

  // 3
  {
    heading: 'Published on CEM on',
    text: datasheet.publishedAt
      ? new Date(datasheet.publishedAt)?.toLocaleDateString('en-US')
      : 'No date',
    colSize:
      datasheet?.keywords?.data &&
      Array.isArray(datasheet.keywords.data) &&
      datasheet.keywords.data.length > 0
        ? 4
        : 12,
  },
  {
    heading: 'Keywords',
    text: datasheet.keywords
      ? datasheet.keywords.data.map((item) => item.attributes.name).join(', ')
      : null,
    colSize: datasheet?.keywords ? 8 : 0,
  },

  // 4
  { heading: 'Location', text: datasheet.location ?? null },
  { heading: 'Interviewee', text: datasheet.Interviewee ?? null },

  // 5
  {
    heading: 'Collaborators',
    text: datasheet.collaborators ?? null,
  },
  {
    heading: 'Social Actors',
    text: datasheet.socialActor ?? null,
  },

  // 6
  {
    heading: 'Dublin Core Type',
    text: datasheet.dublin_core_type?.data?.attributes.name ?? null,
  },
  {
    heading: 'Category',
    text: datasheet.category?.data?.attributes.name ?? null,
  },

  // 7
  {
    heading: 'Document Type',
    text: datasheet.doc_type?.data?.attributes.name ?? null,
  },
  {
    heading: 'Language',
    text: datasheet.language?.data?.attributes.name ?? null,
  },

  // 8
  {
    heading: 'Rights',
    text: datasheet.rights ?? null,
  },
  {
    heading: 'Rights annotation',
    text: datasheet.rightsAnnotation ?? null,
  },

  // 9
  {
    heading: 'Use Restriction',
    text: datasheet.useRestriction ?? null,
  },
  {
    heading: 'Distribution Restriction',
    text: datasheet.distributionRestriction ?? null,
  },

  // 10
  {
    heading: 'Publisher',
    text: datasheet.subject ?? null,
  },
  {
    heading: 'Publication Date',
    text: datasheet.publicationDate
      ? new Date(datasheet.publicationDate)?.toLocaleDateString('en-US')
      : 'No date',
  },

  // 11
  {
    heading: 'Format',
    text: datasheet.format ?? null,
  },
  {
    heading: 'Coverage',
    text: datasheet.coverage ?? null,
  },

  // 12
  {
    heading: 'Number of pages',
    text: datasheet.numberOfPages ?? null,
  },
  {
    heading: 'Dimensions',
    text: datasheet.sizeDimensions ?? null,
  },

  // 13
  {
    heading: 'Collection Date',
    text: datasheet.collectionDate
      ? new Date(datasheet.collectionDate)?.toLocaleDateString('en-US')
      : 'No date',
  },
  {
    heading: 'Source',
    text: datasheet.source ?? null,
  },
  {
    heading: 'File type',
    text:
      datasheet.file?.data?.[0]?.attributes?.ext
        .replace(/[^a-zA-Z0-9]/g, '')
        .toUpperCase() ?? null,
  },

  // 14
  {
    heading: 'Citation',
    text: datasheet.citation ?? null,
    colSize: 8,
  },
];
