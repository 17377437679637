import React from 'react';
import { isMobileOnly } from 'react-device-detect';

import Img from './Img';
import { siteUrl } from '../../utils/constants';

const getImageUrl = (formats) => {
  if (!formats) return '';
  return isMobileOnly
    ? formats?.small?.url
    : formats?.large?.url || formats?.small?.url;
};

const ImagesSequence = ({ images }) =>
  images.map((image, index) => {
    const { formats, caption } = image.attributes || {};
    const imageUrl = `${siteUrl}${getImageUrl(formats)}`;

    return (
      <div key={`imagesSequence${index}`} className="container mb--100">
        <Img url={imageUrl} />
        {caption && <p className="text-center mt_dec--20 mb--80">{caption}</p>}
      </div>
    );
  });

export default ImagesSequence;
