import React from 'react';
import PropTypes from 'prop-types';
import { BlocksRenderer } from '@strapi/blocks-react-renderer';
import TextFootNote from './TextFootNote';

const TextBlockTwo = ({ content }) => {
  if (content) {
    return (
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="inner-wrapper">
              <div className="inner">
                <BlocksRenderer
                  content={content}
                  blocks={{
                    link: ({ children, url }) => (
                      <a
                        className="theme-gradient"
                        href={url}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        {children}
                      </a>
                    ),
                    quote: ({ children }) => (
                      <blockquote className="rn-blog-quote text-block-quote">
                        <p>
                          {children.map((child) => {
                            if (
                              typeof child?.props?.text === 'string' &&
                              child?.props?.text?.indexOf('footnote=') !== -1
                            ) {
                              return (
                                <TextFootNote
                                  tbFootNote={child?.props?.text?.split(
                                    'footnote='
                                  )}
                                />
                              );
                            }

                            return child;
                          })}
                        </p>
                      </blockquote>
                    ),
                    paragraph: ({ children }) => {
                      const mappedChildren = children.map(
                        (child, mappedChildIndex) => {
                          if (
                            typeof child?.props?.text === 'string' &&
                            child?.props?.text?.indexOf('footnote=') !== -1
                          ) {
                            return (
                              <TextFootNote
                                key={mappedChildIndex}
                                tbFootNote={child?.props?.text?.split(
                                  'footnote='
                                )}
                              />
                            );
                          }

                          return child;
                        }
                      );

                      return (
                        <p className="text-neutral900 max-w-prose">
                          {mappedChildren}
                        </p>
                      );
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return '';
};

TextBlockTwo.propTypes = {
  content: PropTypes.array,
};

export default TextBlockTwo;
