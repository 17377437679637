import React from 'react';

import { convertToRoman } from '../../utils/funcs';
import { chapterNames } from '../../utils/constants';

const chapterButtons = ({ activeContainer, activateContainer, caseName }) => {
  let mappableData = [];

  if (caseName) {
    const chaptersArray = [];

    chapterNames.forEach((chapterName, index) => {
      if (
        caseName?.attributes?.[chapterName] &&
        Array.isArray(caseName?.attributes?.[chapterName]) &&
        caseName?.attributes?.[chapterName].length > 0
      ) {
        chaptersArray.push(index + 1);
      }
    });

    mappableData = chaptersArray;
  }
  if (caseName.textBlock) {
    mappableData = caseName.textBlock;
  }

  if (caseName.headingTextBlock) {
    mappableData = caseName.headingTextBlock;
  }

  return (
    <div className="container text-center mt--100">
      {mappableData && mappableData !== ''
        ? mappableData.map((item, index) => (
            <a
              key={`chapterButton${index}`}
              className={`btn-default ${
                activeContainer !== index ? 'btn-border' : ''
              } size-md color-secondary ml--20 mt--20`}
              role="button"
              tabIndex={0}
              onClick={() => activateContainer(index)}
              onKeyDown={() => activateContainer(index)}
              href="#chapters"
            >
              {item.textBlock ? (
                <span>{item.heading}</span>
              ) : (
                <span>{convertToRoman(index + 1)}</span>
              )}
            </a>
          ))
        : ''}
    </div>
  );
};

export default chapterButtons;
