import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from 'react-icons/fi';

import PageHelmet from './common/Helmet';
import Breadcrumb from './common/Breadcrumb';
import Pagination from './common/Pagination';
import CasesList from './blog/CasesList';
import Header from './common/Header';
import FooterTwo from './common/FooterTwo';

const Cases = ({ cases }) => {
  const backgroundSection = 'About';
  const section = 'Cases';
  const displayedCases = 6;

  const [activePagTab, setActivePagTab] = useState(1);
  const handleSetActivePagTab = useCallback(
    (value) => setActivePagTab(value),
    []
  );

  // Calculate pagination
  const pagInt = Math.ceil(cases.length / displayedCases);
  const postList =
    cases &&
    Array.isArray(cases) &&
    cases?.slice(
      (activePagTab - 1) * displayedCases,
      activePagTab * displayedCases
    );

  return (
    <>
      <PageHelmet pageTitle={section} />
      <Header />
      <Breadcrumb backgroundSection={backgroundSection} title={section} />

      <div className="rn-blog-area ptb--120 bg_color--1">
        <div className="container">
          <CasesList postList={postList} />
          {typeof pagInt === 'number' && pagInt > 1 && (
            <div className="row mt--60">
              <div className="col-lg-12">
                <Pagination
                  pagInt={pagInt}
                  activePagTab={activePagTab}
                  setActivePagTab={handleSetActivePagTab}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>

      <FooterTwo />
    </>
  );
};

Cases.propTypes = {
  cases: PropTypes.array.isRequired,
};

export default Cases;
