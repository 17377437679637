import React, { useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import PropTypes from 'prop-types';

import ArchiveElement from '../objects/ArchiveElement';

import { shuffleArray } from '../../utils/shuffleArray';

const TabStyleThree = ({ column, documents, videos }) => {
  const [tabOne, setTabOne] = useState(videos);
  const [tabTwo, setTabTwo] = useState(documents);

  useEffect(() => {
    const randomizedDocuments = shuffleArray(documents);
    const randomizedVideos = shuffleArray(videos);
    setTabOne(randomizedVideos);
    setTabTwo(randomizedDocuments);
  }, [videos, documents]);

  const renderTabContent = (tabData, type) =>
    tabData.map((item, index) => (
      <ArchiveElement key={index} column={column} item={item} type={type} />
    ));

  return (
    <div>
      <Tabs>
        <div className="row text-center">
          <div className="col-lg-12">
            <div className="tablist-inner">
              <TabList className="pv-tab-button text-center mt--0">
                <Tab>
                  <span>All</span>
                </Tab>
                <Tab>
                  <span>Video</span>
                </Tab>
                <Tab>
                  <span>Documents</span>
                </Tab>
              </TabList>
            </div>
          </div>
        </div>

        <TabPanel className="row row--35">
          {tabOne.length !== 0 && renderTabContent(tabOne, 'video')}
          {tabTwo.length !== 0 && renderTabContent(tabTwo, 'document')}
        </TabPanel>

        <TabPanel className="row row--35">
          {tabOne.length !== 0 && renderTabContent(tabOne, 'video')}
        </TabPanel>

        <TabPanel className="row row--35">
          {tabTwo.length !== 0 && renderTabContent(tabTwo, 'document')}
        </TabPanel>
      </Tabs>
    </div>
  );
};

TabStyleThree.propTypes = {
  column: PropTypes.string,
  documents: PropTypes.array,
  videos: PropTypes.array,
};

export default TabStyleThree;
