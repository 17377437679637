import React from 'react';
import PropTypes from 'prop-types';

import Img from './Img';
import ImgText from './ImgText';
import ImgFullWidth from './ImgFullWidth';
import ImgFullScreen from './ImgFullScreen';

const Image = ({
  caption = '',
  elementId = '',
  fileUrl,
  url,
  type,
  cssClass,
}) => {
  const captionArray =
    caption.substr('translation=') !== -1
      ? caption.split('translation=')
      : [caption];

  if (type && type.indexOf('standard') !== -1) {
    return (
      <>
        <Img
          cssClass={cssClass}
          elementId={elementId}
          url={url}
          type={type}
          fileUrl={fileUrl}
        />
        {caption !== '' ? (
          <p className="text-center mt_dec--20 mb--80">{caption}</p>
        ) : (
          ''
        )}
      </>
    );
  }
  if (type === 'fullScreen') {
    return (
      <ImgFullScreen
        captionArray={captionArray}
        elementId={elementId}
        url={url}
      />
    );
  }
  if (type === 'fullWidth') {
    return (
      <ImgFullWidth
        captionArray={caption}
        cssClass={cssClass}
        elementId={elementId}
        url={url}
      />
    );
  }
  if (type === 'text') {
    return (
      <ImgText captionArray={captionArray} elementId={elementId} url={url} />
    );
  }
  return '';
};

Image.propTypes = {
  caption: PropTypes.string,
  elementId: PropTypes.string,
  fileUrl: PropTypes.string,
  url: PropTypes.string,
  type: PropTypes.string,
  cssClass: PropTypes.string,
};

export default Image;
