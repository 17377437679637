import React, { useEffect, useMemo, useState, memo } from 'react';
import ReactMapGL from 'react-map-gl';
import { FiHeadphones, FiMail, FiMapPin, FiChevronUp } from 'react-icons/fi';
import ScrollToTop from 'react-scroll-up';
import PropTypes from 'prop-types';
import { BlocksRenderer } from '@strapi/blocks-react-renderer';
import ContactTwo from './contact/ContactTwo';
import BrandTwo from './BrandTwo';
import RenderLoader from './common/RenderLoader';
import PageHelmet from './common/Helmet';
import Header from './common/Header';
import Breadcrumb from './common/Breadcrumb';
import FooterTwo from './common/FooterTwo';
import { accessToken, siteUrl } from '../utils/constants';

const settings = {
  dragPan: false,
  dragRotate: false,
  scrollZoom: false,
  touchZoom: false,
  touchRotate: false,
  keyboard: false,
  doubleClickZoom: false,
  width: '100%',
  height: '100%',
};

const sanitizeContact = (contact) => ({
  superHeading: contact.superHeading || '',
  heading: contact.heading || '',
  text: contact.text || [],
  phone: contact.phone || '',
  email: contact.email || '',
  address: contact.address || [],
  formText: contact.formText || { image: { data: { attributes: {} } } }, // Initialize defaults
  latitude: contact.latitude || 0,
  longitude: contact.longitude || 0,
  mapZoom: contact.mapZoom || 0,
});

const Contact = ({ contact = {}, sponsors = {} }) => {
  const sanitizedContact = useMemo(() => sanitizeContact(contact), [contact]);
  const backgroundSection = 'Archive';
  const section = 'Contact';
  const [contactState, setContactState] = useState({
    viewPort: {},
    error: null,
  });

  useEffect(() => {
    if (sanitizedContact.latitude && sanitizedContact.longitude) {
      setContactState((prevState) => ({
        ...prevState,
        viewPort: {
          latitude: sanitizedContact.latitude,
          longitude: sanitizedContact.longitude,
          zoom: sanitizedContact.mapZoom,
        },
        error: null, // Clear any previous error
      }));
    } else {
      setContactState((prevState) => ({
        ...prevState,
        error: 'Error loading map: Latitude and longitude not provided.',
      }));
    }
  }, [sanitizedContact]);

  return (
    <>
      <PageHelmet pageTitle={section} />
      <Header section={section} />
      <Breadcrumb title={section} backgroundSection={backgroundSection} />
      <div className="rn-contact-top-area ptb--120 bg_color--5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title mb--30 text-center">
                {sanitizedContact.superHeading ? (
                  <>
                    <span className="subtitle">
                      {sanitizedContact.superHeading}
                    </span>
                    <h3 className="title mt--20 mb--50">
                      {sanitizedContact.heading}
                    </h3>
                    <BlocksRenderer content={sanitizedContact.text} />
                  </>
                ) : (
                  <RenderLoader />
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <ContactDetail
              icon={<FiHeadphones />}
              title="Contact Phone Number"
              content={
                <p>
                  <a href={`tel:${sanitizedContact.phone}`}>
                    {sanitizedContact.phone}
                  </a>
                </p>
              }
            />
            <ContactDetail
              icon={<FiMail />}
              title="Our Email Address"
              content={
                <p>
                  <a href={`mailto:${sanitizedContact.email}`}>
                    {sanitizedContact.email}
                  </a>
                </p>
              }
            />
            <ContactDetail
              icon={<FiMapPin />}
              title="Our Location"
              content={<BlocksRenderer content={sanitizedContact.address} />}
            />
          </div>
        </div>
      </div>
      <div className="rn-contact-page ptb--120 bg_color--1">
        {contact?.formText &&
        contact?.formText?.image &&
        contact?.formText?.image?.data ? (
          <ContactTwo
            caption={
              contact?.formText?.image?.data?.[0]?.attributes?.caption || ''
            }
            contactImg={`${siteUrl}${
              contact?.formText?.image?.data?.[0]?.attributes?.formats?.medium
                ?.url || ''
            }`}
            heading={contact?.formText?.heading || ''}
            email={contact?.email || ''}
            superHeading={contact?.formText?.superHeading || ''}
            phone={contact?.phone || ''}
          />
        ) : (
          <RenderLoader />
        )}
      </div>
      <div
        className="rn-contact-map-area position-relative"
        style={{ height: '650px', width: '100%' }}
      >
        {contactState.viewPort.latitude ? (
          <ReactMapGL
            latitude={contactState.viewPort.latitude}
            longitude={contactState.viewPort.longitude}
            zoom={contactState.viewPort.zoom}
            dragPan={settings.dragPan}
            dragRotate={settings.dragRotate}
            scrollZoom={settings.scrollZoom}
            touchZoom={settings.touchZoom}
            touchRotate={settings.touchRotate}
            keyboard={settings.keyboard}
            doubleClickZoom={settings.doubleClickZoom}
            width={settings.width}
            height={settings.height}
            mapboxApiAccessToken={accessToken}
            mapStyle="mapbox://styles/mapbox/outdoors-v11"
            onViewportChange={(viewport) =>
              setContactState((prevState) => ({
                ...prevState,
                viewPort: viewport,
              }))
            }
          />
        ) : (
          <p style={{ color: 'red', textAlign: 'center', marginTop: '20px' }}>
            {contactState.error}
          </p>
        )}
      </div>
      <BrandTwo sponsors={sponsors} />
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      <FooterTwo />
    </>
  );
};

const ContactDetail = ({ icon, title, content }) => (
  <div className="col-lg-4 col-md-6 col-sm-6 col-12">
    <div className="rn-address">
      <div className="icon">{icon}</div>
      <div className="inner">
        <h4 className="title">{title}</h4>
        {content}
      </div>
    </div>
  </div>
);

ContactDetail.propTypes = {
  icon: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
};

Contact.propTypes = {
  contact: PropTypes.shape({
    superHeading: PropTypes.string,
    heading: PropTypes.string,
    textBlock: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    address: PropTypes.array,
    formText: PropTypes.object,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    mapZoom: PropTypes.number,
  }),
  sponsors: PropTypes.object,
};

export default memo(Contact);
