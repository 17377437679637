import React from 'react';

import PropTypes from 'prop-types';
import TextUrl from './TextUrl';
import TextFootNote from './TextFootNote';

const TextQuote = ({ quote }) => {
  if (quote) {
    return (
      <p>
        {quote.indexOf('link=') !== -1 ? quote?.split('link=')[0] : ''}

        {quote.indexOf('footnote=') !== -1 ? quote?.split('footnote=')[0] : ''}

        {quote.indexOf('link=') === -1 && quote?.indexOf('footnote=') === -1
          ? quote
          : ''}

        {quote.indexOf('link=') !== -1 && (
          <span>
            {quote && quote?.indexOf('link=') !== -1 && (
              <TextUrl tbLinks={quote?.split('link=')} />
            )}
          </span>
        )}

        {quote && quote?.indexOf('footnote=') !== -1 && (
          <TextFootNote tbFootNote={quote?.split('footnote=')} />
        )}
      </p>
    );
  }

  return null;
};

TextQuote.propTypes = {
  quote: PropTypes.string,
};

export default TextQuote;
