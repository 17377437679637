import React from 'react';
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { FiChevronDown, FiStar } from 'react-icons/fi';
import PropTypes from 'prop-types';

const Timeline = ({ timeline }) => (
  <VerticalTimeline>
    {timeline.length !== 0
      ? timeline.map((line, index) => {
          const bgColor = 'rgba(25, 79, 138, 1)';
          if (index === 0) {
            return (
              <VerticalTimelineElement
                key={`timelineElement${index}`}
                className="vertical-timeline-element--work"
                contentStyle={{
                  color: '#fff',
                }}
                contentArrowStyle={{
                  borderRight: `7px solid  ${bgColor}`,
                }}
                date={line.date}
                iconStyle={{ background: bgColor, color: '#fff' }}
                icon={<FiChevronDown />}
              >
                <h3 className="vertical-timeline-element-title">
                  {line.heading}
                </h3>
                <h4 className="vertical-timeline-element-subtitle">
                  {line.subHeading}
                </h4>
                <p>{line.textBlock}</p>
              </VerticalTimelineElement>
            );
          }

          return (
            <VerticalTimelineElement
              key={`timelineElementimg${index}`}
              className="vertical-timeline-element--work"
              contentArrowStyle={{
                borderRight: `7px solid  ${bgColor}`,
              }}
              date={line.date}
              iconStyle={{ background: bgColor, color: '#fff' }}
              icon={<FiChevronDown />}
            >
              <h3 className="vertical-timeline-element-title">
                {line.heading}
              </h3>
              <h4 className="vertical-timeline-element-subtitle">
                {line.subHeading}
              </h4>
              <p>{line.textBlock}</p>
            </VerticalTimelineElement>
          );
        })
      : ''}

    <VerticalTimelineElement
      iconStyle={{ background: 'rgba(153, 0, 0, 1)', color: '#fff' }}
      icon={<FiStar />}
    />
  </VerticalTimeline>
);

Timeline.propTypes = {
  timeline: PropTypes.array,
};

export default Timeline;
