import React from 'react';
import PropTypes from 'prop-types';

import VideoFullScreen from './VideoFullScreen';
import VideoFullWidth from './VideoFullWidth';
import VideoStandard from './VideoStandard';

const Video = ({ elementId = '', type, videoData, vidImg, cssClass }) => {
  if (videoData) {
    if (type === 'fullScreen' && videoData.url && videoData.url !== '') {
      return (
        <div className="mb-xl-5">
          <VideoFullScreen
            elementId={elementId}
            cssClass={cssClass}
            url={videoData.url.trim().replace('https://vimeo.com/', '')}
          />
        </div>
      );
    }

    if (type === 'fullWidth') {
      return (
        <div className="mb-xl-5">
          <VideoFullWidth
            elementId={elementId}
            url={
              videoData?.videoFile?.data?.attributes?.url
                ? videoData?.videoFile?.data?.attributes?.url
                : videoData?.url
            }
          />
        </div>
      );
    }

    if (type === 'standard' && videoData.url && videoData?.url !== '') {
      return (
        <VideoStandard
          elementId={elementId}
          cssClass={cssClass}
          start={videoData.start !== null ? `#t=${videoData.start}` : ''}
          url={videoData.url}
          vidImg={vidImg}
        />
      );
    }
  }

  return '';
};

Video.propTypes = {
  elementId: PropTypes.string,
  type: PropTypes.string,
  videoData: PropTypes.shape({
    url: PropTypes.string,
    start: PropTypes.string,
    videoFile: PropTypes.object,
  }),
  vidImg: PropTypes.string,
  cssClass: PropTypes.string,
};

export default Video;
