import React, { useContext } from 'react';
import slugify from 'slugify';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import GifPlayer from 'react-gif-player';
import { siteUrl } from '../../utils/constants';
import { scrollToTop } from '../../utils/funcs';
import { DataContext } from '../../context/DataContext';

const getImageUrl = (item) => {
  if (
    item.attributes &&
    item.attributes.contentType &&
    item.attributes.contentType.image &&
    item.attributes.contentType.image.data
  ) {
    const { formats } = item.attributes.contentType.image.data.attributes;
    return formats.small !== undefined
      ? formats.small.url
      : formats.thumbnail.url;
  }
  return '';
};

const ArchiveElement = ({ column, item, type }) => {
  const { setCollectionNameAndItemId } = useContext(DataContext);

  const handleClick = () => {
    setCollectionNameAndItemId(type, item.id);
    scrollToTop();
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      setCollectionNameAndItemId(type, item.id);
      scrollToTop();
    }
  };

  const fileAttributes = item?.attributes?.file?.data?.[0]?.attributes;
  const isGif = fileAttributes?.ext === '.gif';
  const fileUrl = fileAttributes?.url;
  const imageUrl = getImageUrl(item);

  return (
    <div className={column}>
      <div className="item-portfolio-static">
        <div>
          <div className="portfolio-static">
            <div className="thumbnail-inner">
              <div className="thumbnail">
                <Link
                  to={`archive/${
                    item.attributes && item.attributes.contentType
                      ? slugify(item.attributes.contentType.title, {
                          lower: true,
                          strict: true,
                          trim: true,
                        })
                      : ''
                  }`}
                  onClick={handleClick}
                  onKeyDown={handleKeyDown}
                >
                  {imageUrl && !isGif && (
                    <img src={`${siteUrl}${imageUrl}`} alt="Portfolio Images" />
                  )}

                  {isGif && fileUrl && (
                    <GifPlayer
                      gif={`${siteUrl}${fileUrl}`}
                      autoplay
                      className="image-gif-centered"
                    />
                  )}
                </Link>
              </div>
            </div>
            <div className="content">
              <div className="inner">
                <p>
                  {item.attributes.contentType.doc_type &&
                  item.attributes.contentType.doc_type.data
                    ? item.attributes.contentType.doc_type.data.attributes.name
                    : ''}
                </p>
                <h4>
                  <Link
                    to={`archive/${
                      item.attributes && item.attributes.contentType
                        ? slugify(item.attributes.contentType.title, {
                            lower: true,
                            strict: true,
                            trim: true,
                          })
                        : ''
                    }`}
                    onClick={handleClick}
                    onKeyDown={handleKeyDown}
                  >
                    {item.attributes && item.attributes.contentType
                      ? item.attributes.contentType.title
                      : ''}
                  </Link>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ArchiveElement.propTypes = {
  column: PropTypes.string,
  item: PropTypes.object,
  type: PropTypes.string,
};

export default ArchiveElement;
