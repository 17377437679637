import React, { useContext, useEffect } from 'react';
import {
  FaTwitter,
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn,
  FaVimeo,
} from 'react-icons/fa';

import PropTypes from 'prop-types';
import { DataContext } from '../../context/DataContext';

const FooterTwo = ({ setToTop, toTop }) => {
  const currentYear = new Date();
  const { socialMedia } = useContext(DataContext);

  const disableCarousel = () => {
    const footerElement = document.querySelector('.footer-style-2');

    if (footerElement) {
      if (window.scrollY > footerElement.offsetTop - 500 && toTop === 0) {
        setToTop(1);
      }
    }
  };

  useEffect(() => {
    if (window) {
      window.addEventListener('scroll', disableCarousel);
    }

    return () => {
      window.removeEventListener('scroll', disableCarousel);
    };
  });

  return (
    <div className="footer-style-2 ptb--30 bg_color--6">
      <div className="wrapper plr--50 plr_sm--20">
        <div className="row align-items-center justify-content-between">
          <div className="col-lg-4 col-md-6 col-sm-6 col-12">
            <div className="inner" />
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6 col-12">
            <div className="inner text-center">
              <ul className="social-share rn-lg-size d-flex justify-content-center liststyle">
                {socialMedia.map((val, i) => (
                  <li key={i}>
                    {val.icon.indexOf('behance') !== -1 ? (
                      ''
                    ) : (
                      <a
                        href={`${val.url}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        name={val.icon}
                      >
                        {val.icon.indexOf('facebook') !== -1 ? (
                          <FaFacebookF />
                        ) : (
                          ''
                        )}
                        {val.icon.indexOf('instagram') !== -1 ? (
                          <FaInstagram />
                        ) : (
                          ''
                        )}
                        {val.icon.indexOf('linkedin') !== -1 ? (
                          <FaLinkedinIn />
                        ) : (
                          ''
                        )}
                        {val.icon.indexOf('twitter') !== -1 ? (
                          <FaTwitter />
                        ) : (
                          ''
                        )}
                        {val.icon.indexOf('vimeo') !== -1 ? <FaVimeo /> : ''}
                      </a>
                    )}
                  </li>
                ))}
              </ul>
              <div className="text mt--20">
                <p>{`Copyright © ${currentYear.getFullYear()} CEM. All Rights Reserved.`}</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-sm-12 col-12">
            <div className="inner text-lg-right text-center mt_md--20 mt_sm--20" />
          </div>
        </div>
      </div>
    </div>
  );
};

FooterTwo.propTypes = {
  setToTop: PropTypes.func,
  toTop: PropTypes.number,
};

export default FooterTwo;
