import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player'; // Import ReactPlayer

const VideoModal = ({ show, onClose, url, description }) => {
  const closeButtonRef = useRef(null);
  const scrollPosition = useRef(0);

  useEffect(() => {
    if (show) {
      scrollPosition.current = window.scrollY;
      closeButtonRef.current.focus();
      document.body.classList.add('modal-open');

      const handleKeyDown = (e) => {
        if (e.key === 'Escape') onClose();
      };

      window.addEventListener('keydown', handleKeyDown);
      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    }
    document.body.classList.remove('modal-open');
    window.scrollTo(0, scrollPosition.current);
  }, [show, onClose]);

  if (!show) return null;

  return (
    <div className="modal-overlay" role="dialog" aria-modal="true">
      <div
        className="modal-content p-lg-5"
        onClick={(e) => e.stopPropagation()}
        role="presentation"
      >
        <button
          onClick={onClose}
          className="close-modal"
          aria-label="Close modal"
          type="button"
          ref={closeButtonRef}
        >
          &times;
        </button>

        <ReactPlayer url={url} width="100%" height="75.76%" controls />

        <p className="modal-description">{description}</p>
      </div>
      <button
        className="modal-overlay-button"
        type="button"
        aria-label="Close modal overlay"
      />
    </div>
  );
};

VideoModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  url: PropTypes.string,
  description: PropTypes.string,
};

export default VideoModal;
