import React from 'react';
import PropTypes from 'prop-types';
import FormStyle from './FormStyle';

const ContactTwo = ({
  caption,
  contactImg,
  heading,
  email,
  phone,
  superHeading,
}) => (
  <div className="contact-form--1">
    <div className="container">
      <div className="row row--35 align-items-start">
        <div className="col-lg-6 order-2 order-lg-1">
          <div className="section-title text-left mb--50">
            <span className="subtitle">{superHeading}</span>
            <h3 className="title">{heading}</h3>
            <div className="im_address">
              <span>Call us directly:</span>
              <a className="link im-hover" href={`tel:${phone}`}>
                {phone}
              </a>
            </div>
            <div className="im_address mt--5">
              <span>Contact Email:</span>
              <a className="link im-hover" href={`mailto:${email}`}>
                {email}
              </a>
            </div>
          </div>
          <FormStyle />
        </div>
        <div className="col-lg-6 order-1 order-lg-2">
          <div className="thumbnail mb_md--30 mb_sm--30">
            <img
              className="large-centered-img"
              src={contactImg}
              alt={caption}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
);

ContactTwo.propTypes = {
  caption: PropTypes.string.isRequired,
  contactImg: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  superHeading: PropTypes.string.isRequired,
};

export default ContactTwo;
