import React, { memo, useCallback } from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from 'react-icons/fi';
import { isMobileOnly } from 'react-device-detect';

import PropTypes from 'prop-types';
import PageHelmet from './common/Helmet';
import Header from './common/Header';
import Breadcrumb from './common/Breadcrumb';
import Image from './objects/Image';
import BrandTwo from './BrandTwo';
import FooterTwo from './common/FooterTwo';

import { imgSize } from '../utils/funcs';

import TabThree from './tab/TabThree';

const Archive = ({ backgroundImages, documents, sponsors, videos }) => {
  const section = 'Archive';

  const backgroundImg = useCallback(
    () =>
      backgroundImages.find(
        (item) =>
          item.attributes.section &&
          item.attributes.section.toLowerCase() === section.toLowerCase()
      ) ?? null,
    [backgroundImages, section]
  );

  const renderTabThree = useCallback(
    () => (
      <div className="designer-portfolio-area ptb--120 bg_color--1">
        <div className="wrapper plr--70 plr_sm--30 plr_md--30">
          <TabThree
            column="col-lg-4 col-md-6 col-sm-6 col-12"
            documents={documents}
            videos={videos}
          />
        </div>
      </div>
    ),
    [documents, videos]
  );

  const renderImage = useCallback(() => {
    const imgAttributes = backgroundImg()?.attributes?.image?.data?.attributes;
    if (!imgAttributes) {
      return null;
    }
    return (
      <>
        {backgroundImages.length > 0 && (
          <Image
            caption={imgAttributes?.caption ?? ''}
            cssClass="mb-dec--40"
            imgType="fullWidth"
            imgUrl={imgSize(imgAttributes, isMobileOnly)}
          />
        )}
      </>
    );
  }, [backgroundImages, backgroundImg]);

  return (
    <>
      <PageHelmet pageTitle="About" />

      <Header section={section} />
      {/* Start Breadcrumb Area */}
      <Breadcrumb backgroundSection={section} title="Archive" />
      {/* End Breadcrumb Area */}

      {renderTabThree()}

      {renderImage()}

      {/* Start Brand Area */}
      <BrandTwo sponsors={sponsors} />
      {/* End Brand Area */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}

      <FooterTwo />
    </>
  );
};

Archive.propTypes = {
  backgroundImages: PropTypes.array,
  documents: PropTypes.array,
  sponsors: PropTypes.object,
  videos: PropTypes.array,
};

export default memo(Archive);
