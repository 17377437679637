import React from 'react';
import PropTypes from 'prop-types';

import { isMobileOnly } from 'react-device-detect';

import ImagesCarousel from './ImagesCarousel';
import ImagesMovingText from './ImagesMovingText';
import ImagesSequence from './ImagesSequence';

const Images = ({ images, type, cssClass, toTop }) => {
  if (type === 'carousel') {
    return <ImagesCarousel images={images} toTop={toTop} cssClass={cssClass} />;
  }

  if (type === 'movingText' && !isMobileOnly) {
    return (
      <div className="mb-xl-5">
        <ImagesMovingText images={images} />
      </div>
    );
  }

  if (type === 'movingText' && isMobileOnly) {
    return <ImagesSequence images={images} />;
  }

  if (type === 'sequence') {
    return <ImagesSequence images={images} />;
  }

  return '';
};

Images.propTypes = {
  images: PropTypes.array,
  type: PropTypes.string,
  cssClass: PropTypes.string,
  toTop: PropTypes.bool,
};

export default Images;
