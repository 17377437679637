import React, { useState } from 'react';
import emailJs from '@emailjs/browser';

const Result = () => (
  <p className="success-message">
    Your Message has been successfully sent. I will contact you soon.
  </p>
);
const FormStyle = () => {
  const [result, showresult] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    emailJs
      .sendForm(
        'service_34t6dn9',
        'template_jbtst07',
        e.target,
        'user_vWpvpdR4ZvWrZ1nZFY8CD'
      )
      .then(
        (message) => {
          console.log(message.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
    showresult(true);
  };

  setTimeout(() => {
    showresult(false);
  }, 5000);

  return (
    <div className="form-wrapper">
      <form action="" onSubmit={sendEmail}>
        <div className="rn-form-group">
          <input type="text" name="fullname" placeholder="Your Name" required />
        </div>

        <div className="rn-form-group">
          <input type="email" name="email" placeholder="Your Email" required />
        </div>

        <div className="rn-form-group">
          <input type="text" name="phone" placeholder="Phone Number" required />
        </div>

        <div className="rn-form-group">
          <input
            type="text"
            name="affiliation"
            placeholder="Affiliation"
            required
          />
        </div>

        <div className="rn-form-group">
          <input type="text" name="subject" placeholder="Subject" required />
        </div>

        <div className="rn-form-group">
          <textarea name="message" placeholder="Your Message" required />
        </div>

        <div className="rn-form-group">
          <button
            className="btn-default size-md"
            type="submit"
            value="submit"
            name="submit"
            id="mc-embedded-subscribe"
          >
            Submit Now
          </button>
        </div>

        <div className="rn-form-group">{result ? <Result /> : null}</div>
      </form>
    </div>
  );
};

export default FormStyle;
