import React from 'react';
import PropTypes from 'prop-types';

import AudioPlayer from 'react-h5-audio-player';

const AudioFile = ({ url = '' }) => (
  <div className="text-center">
    <AudioPlayer src={url} />
  </div>
);

AudioFile.propTypes = {
  url: PropTypes.string,
};

export default AudioFile;
