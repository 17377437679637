import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FiChevronUp } from 'react-icons/fi';
import { isMobileOnly } from 'react-device-detect';
import ScrollToTop from 'react-scroll-up';
import PageHelmet from './common/Helmet';
import Header from './common/Header';
import Breadcrumb from './common/Breadcrumb';
import AboutComp from './about/About';
import TeamMember from './about/TeamMember';
import Testimonial from './Testimonial';
import Image from './objects/Image';
import BrandTwo from './BrandTwo';
import FooterTwo from './common/FooterTwo';
import TextBlock from './objects/TextBlock';
import { imgSize } from '../utils/funcs';

const sanitizeAbout = (about) => ({
  superHeadingOne: about.superHeadingOne || '',
  headingOne: about.headingOne || '',
  textOne: about.textOne || '',
  superHeadingTwo: about.superHeadingTwo || '',
  headingTwo: about.headingTwo || '',
  textTwo: about.textTwo || '',
  headingThree: about.headingThree || '',
  textThree: about.textThree || '',
});

const About = ({ about = {}, backgroundImages = [], sponsors = {} }) => {
  const section = 'About';
  const memoizedAbout = useMemo(() => sanitizeAbout(about), [about]);

  const memoizedBackgroundImg = useMemo(
    () =>
      backgroundImages.find(
        (item) => item?.attributes?.section === section.toLowerCase()
      ),
    [backgroundImages]
  );

  // Determine background image attachment type
  const backgroundImageAttribute = isMobileOnly ? 'scroll' : 'fixed';

  return (
    <>
      <PageHelmet pageTitle={section} />
      <Header section={section} />
      <Breadcrumb backgroundSection={section} title={section} />

      <div className="about-area ptb--100 bg_color--1">
        <AboutComp
          shHeading={memoizedAbout.superHeadingOne}
          heading={memoizedAbout.headingOne}
          textBlock={memoizedAbout.textOne}
        />
      </div>

      <div
        className={`rn-finding-us-area attachment-${backgroundImageAttribute} rn-finding-us ptb--120 bg_color--1 bg_image`}
        data-black-overlay="5"
        style={{
          backgroundImage: `url(${imgSize(
            memoizedBackgroundImg?.attributes?.image?.data,
            isMobileOnly
          )})`,
          backgroundAttachment: backgroundImageAttribute,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="inner">
                <div className="content-wrapper">
                  <div className="content">
                    <h3 className="theme-gradient">
                      {memoizedAbout.headingTwo}
                    </h3>
                    <TextBlock textBlock={memoizedAbout.textTwo} />
                    <a className="btn-default size-md" href="/methodology">
                      Methodology
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="rn-team-area bg_color--1 ptb--120">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title text-center mb--25">
                <span className="subtitle">
                  {memoizedAbout.superHeadingTwo}
                </span>
                <h3 className="title">{memoizedAbout.headingThree}</h3>
                <TextBlock textBlock={memoizedAbout.textThree} />
              </div>
            </div>
          </div>

          <TeamMember about={about} />
        </div>
      </div>

      <Testimonial sponsors={sponsors.attributes || {}} />

      {backgroundImages.length !== 0 && (
        <Image
          caption={
            memoizedBackgroundImg?.attributes?.image?.data?.attributes
              ?.caption || ''
          }
          cssClass="mb-dec--40"
          imgType="fullWidth"
          imgUrl={imgSize(
            memoizedBackgroundImg?.attributes?.image?.data?.attributes,
            isMobileOnly
          )}
        />
      )}

      <BrandTwo sponsors={sponsors} />

      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>

      <FooterTwo />
    </>
  );
};

About.propTypes = {
  about: PropTypes.object,
  backgroundImages: PropTypes.array,
  sponsors: PropTypes.object,
};

export default memo(About);
