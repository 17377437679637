import React, { useContext } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';
import { imgSize } from '../../utils/funcs';
import { DataContext } from '../../context/DataContext';

const Breadcrumb = ({ backgroundSection = 'About', parent, title }) => {
  const { backgroundImages } = useContext(DataContext);

  const backgroundImage =
    backgroundImages.length !== undefined && backgroundImages.length !== 0
      ? backgroundImages.filter(
          (item) =>
            item.attributes.section === backgroundSection.toLocaleLowerCase()
        )[0]
      : '';

  return (
    <>
      <div
        className="breadcrumb-area rn-bg-color ptb--120 bg_image"
        style={{
          backgroundImage: `url(${
            backgroundImage !== undefined && backgroundImage !== ''
              ? imgSize(backgroundImage.attributes, isMobileOnly)
              : ''
          })`,
          backgroundAttachment: `${isMobileOnly ? 'scroll' : 'fixed'}`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="breadcrumb-inner pt--100">
                <h2 className="title">{title}</h2>
                <ul className="page-list">
                  <li className="rn-breadcrumb-item">
                    <Link to={`${process.env.PUBLIC_URL}`}>Home</Link>
                  </li>
                  {parent ? (
                    <li className="rn-breadcrumb-item">{parent}</li>
                  ) : (
                    ''
                  )}
                  <li className="rn-breadcrumb-item active">{title}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Breadcrumb.propTypes = {
  backgroundSection: PropTypes.string,
  parent: PropTypes.string,
  title: PropTypes.string,
};

export default Breadcrumb;
