import React, { useContext, useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { FiChevronUp } from 'react-icons/fi';
import ScrollToTop from 'react-scroll-up';

import { BlocksRenderer } from '@strapi/blocks-react-renderer';

import slugify from 'slugify';
import PropTypes from 'prop-types';
import Gallery from 'react-photo-gallery';
import PageHelmet from './common/Helmet';
import Header from './common/Header';
import BreadcrumbCases from './common/BreadcrumbCases';
import ChapterButtons from './common/ChapterButtons';
import FooterTwo from './common/FooterTwo';

import Map from './objects/Map';
import BubbleGraph from './objects/BubbleGraph';
import VideoModal from './objects/VideoModal';

import { siteUrl, chapterNames } from '../utils/constants';
import { parseStorageItem, setStorageItem } from '../utils/funcs';
import TextBlock from './objects/TextBlock';
import Video from './objects/Video';
import Image from './objects/Image';
import Images from './objects/Images';
import { DataContext } from '../context/DataContext';
import TextBlockTwo from './objects/TextBlockTwo';
import AudioFile from './objects/AudioFile';

const CaseDetails = ({ setRedirectToCases }) => {
  const { videosVimeo } = useContext(DataContext);
  const casePath = window.location.pathname.replace('/cases/', '');
  const [activeContainer, setActiveContainer] = useState(0);
  const [caseName, setCaseName] = useState(parseStorageItem('caseName') || {});
  const [data, setData] = useState(parseStorageItem('data') || []);
  const [toTop, setToTop] = useState(0);

  const { cases, videos } = useContext(DataContext);

  useEffect(() => {
    setActiveContainer(0);
    if (cases && Array.isArray(cases) && cases.length > 0) {
      const caseData = cases.find(
        (item) =>
          item.attributes.name &&
          slugify(item.attributes.name.toLowerCase()) === casePath
      );

      if (caseData) {
        setCaseName(caseData);
        setStorageItem('caseName', caseData);

        const containerData = caseData.attributes || [];
        setData(containerData);
        setStorageItem('data', containerData);

        const mapDataArray = caseData?.attributes?.map;
        if (mapDataArray?.length) {
          setStorageItem('caseMapData', mapDataArray);

          const userChapters = mapDataArray.map(
            ({ pitch, longitude, latitude, zoom }) => ({
              pitch,
              duration: 6000,
              center: [longitude, latitude],
              zoom,
            })
          );

          setStorageItem('caseMapChapters', userChapters);
        }
      } else {
        setRedirectToCases(true);
      }
    }
  }, [cases, casePath, data.length, setActiveContainer, setRedirectToCases]);

  const activateContainer = (containerId) => {
    setActiveContainer(containerId);
    sessionStorage.setItem('activeChapter', containerId);
  };

  const imgUrl =
    isMobileOnly &&
    caseName &&
    caseName?.attributes?.backgroundImage?.data?.attributes?.formats?.small?.url
      ? caseName?.attributes?.backgroundImage?.data?.attributes?.formats?.small
          ?.url
      : caseName?.attributes?.backgroundImage?.data?.attributes?.url || '';

  const container = data[chapterNames[activeContainer]];

  const divBackgroundCssClass =
    container?.__component === 'map.map'
      ? 'pt--20 pb--70'
      : 'rn-blog-details pt--20 pb--70 bg_color--1';

  const darkBackgroundCssClass =
    container?.__component === 'd3js.d3js' ? '' : '';

  // Vimeo Video
  const imgIndex = (device) => {
    if (device) {
      return 4;
    }

    return 5;
  };

  const obj = {};

  videosVimeo.map(
    (video) =>
      (obj[video.uri.replace('/videos/', '')] =
        video.pictures.sizes[imgIndex(isMobileOnly)].link)
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedVideoUrl, setSelectedVideoUrl] = useState(null);
  const [selectedVideoDescription, setSelectedVideoDescription] =
    useState(null);

  const handleImageClick = (event, { photo }) => {
    setSelectedImage(photo.src);
    setSelectedVideoUrl(photo.url);
    setSelectedVideoDescription(photo.description);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
    setSelectedVideoDescription(null);
  };

  const filteredVideos = videos.filter((video) => {
    const showcaseName =
      video?.attributes?.contentType?.showcase?.data?.attributes?.name;

    return (
      showcaseName?.toLowerCase() === caseName?.attributes?.name?.toLowerCase()
    );
  });

  const photos = filteredVideos
    .map((video) => {
      const imageData = video?.attributes?.contentType?.image?.data?.attributes;

      if (imageData) {
        return {
          key: video.id.toString(),
          description: video?.attributes?.contentType?.description,
          height: imageData.height,
          src: `${siteUrl}${imageData.url}`,
          url: video.attributes.contentType.url,
          width: imageData.width,
        };
      }

      return null;
    })
    .filter((photo) => photo !== null);

  return (
    <>
      <PageHelmet pageTitle="Case Details" />
      <Header stickyNav={false} />

      <BreadcrumbCases
        bgCaseImg={siteUrl + imgUrl}
        subHeading={caseName?.attributes?.subHeading}
        caseName={caseName?.attributes?.name}
        dataOverlay={5}
        toTop={toTop}
        setToTop={setToTop}
      />

      <div
        id="chapters"
        className={`${divBackgroundCssClass} ${darkBackgroundCssClass}`}
      >
        <ChapterButtons
          activeContainer={activeContainer}
          activateContainer={activateContainer}
          caseName={caseName}
        />

        {container &&
          container.map((item, index) => {
            if (item.__component === 'element.heading' && item?.heading) {
              return (
                <TextBlock
                  key={index}
                  caseTitle={item?.heading}
                  cssClass="mt--100 "
                />
              );
            }

            if (item.__component === 'element.video') {
              const vidImg =
                item && item?.url?.indexOf('https://vimeo.com/') !== -1
                  ? obj[item?.url?.replace('https://vimeo.com/', '')]
                  : '';

              return (
                <Video
                  key={index}
                  type={item?.type ?? ''}
                  videoData={item ?? null}
                  vidImg={vidImg}
                />
              );
            }

            if (
              item.__component === 'element.image' &&
              item?.image?.data?.attributes
            ) {
              return (
                <Image
                  key={index}
                  type={item?.type ?? ''}
                  url={siteUrl + item?.image?.data?.attributes?.url}
                  caption={item?.caption ? item.caption : ''}
                />
              );
            }

            if (
              item.__component === 'element.image-collection' &&
              item?.image?.data
            ) {
              return (
                <Images
                  key={index}
                  type={item?.type}
                  images={item.image.data}
                  toTop
                />
              );
            }

            if (item.__component === 'element.text-block' && item?.textBlock) {
              return <TextBlockTwo key={index} content={item.textBlock} />;
            }

            if (item.__component === 'element.preamble' && item.preamble) {
              return (
                <div key={index} className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="inner-wrapper">
                        <div className="inner">
                          <blockquote className="rn-blog-quote">
                            <BlocksRenderer
                              key={index}
                              content={item.preamble}
                            />
                          </blockquote>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }

            if (
              item.__component === 'element.audio-file' &&
              item?.audioFile?.data?.attributes?.url
            ) {
              const audioFileUrl = item?.audioFile?.data?.attributes?.url
                ? `${siteUrl}${item.audioFile.data.attributes.url}`
                : null;

              return (
                <div key={index} className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="inner-wrapper">
                        <div className="inner mt-xl-3 mb-xl-5">
                          <AudioFile url={audioFileUrl} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }

            if (item.__component === 'element.video-gallery') {
              return (
                <div key={index}>
                  <Gallery photos={photos} onClick={handleImageClick} />
                  {selectedVideoUrl && (
                    <VideoModal
                      show={isModalOpen}
                      onClose={closeModal}
                      imageSrc={selectedImage}
                      url={selectedVideoUrl}
                      description={selectedVideoDescription}
                    />
                  )}
                </div>
              );
            }

            return null;
          })}

        {container && container.__component === 'map.map' && (
          <Map mapDataArray={container.map} section={caseName.caseName} />
        )}

        {container && container.__component === 'd3js.d3js' && (
          <BubbleGraph container={container} />
        )}

        <ChapterButtons
          activeContainer={activeContainer}
          activateContainer={activateContainer}
          caseName={caseName}
        />
      </div>

      <div
        className="backto-top"
        onClick={() => setToTop(1)}
        onKeyDown={() => setToTop(1)}
        role="button"
        tabIndex={0}
        aria-label="Button to top"
      >
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>

      <FooterTwo setToTop={setToTop} toTop={toTop} />
    </>
  );
};

CaseDetails.propTypes = {
  setRedirectToCases: PropTypes.func,
};

export default CaseDetails;
