import React, { useState } from 'react';
import Modal from 'react-modal';

import BubbleChart from '@weknow/react-bubble-chart-d3';
import PropTypes from 'prop-types';
import TextBlock from './TextBlock';

const BubbleGraph = ({ container }) => {
  let subtitle;
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalHeading, setModalHeading] = useState('');
  const [modalText, setModalText] = useState([]);

  const openModal = (event) => {
    if (event.target.id !== undefined && event.target.id !== '') {
      if (modalIsOpen === false) {
        setIsOpen(true);
      }

      if (modalIsOpen === true) {
        setIsOpen(false);
      }

      const wordCategory =
        event.target.id.indexOf('-') !== -1
          ? event.target.id.replace('-', '_')
          : event.target.id;

      const filteredText = container.bubbleText.filter(
        (item) => item.category === wordCategory
      );

      const word = event.target.id.replace('-', ' ');

      if (filteredText.length > 0) {
        setModalText(filteredText);
        setModalHeading(word.charAt(0).toUpperCase() + word.slice(1));
      }

      if (filteredText.length === 0) {
        setModalText('');
        setModalHeading(word.charAt(0).toUpperCase() + word.slice(1));
      }
    }
  };

  const afterOpenModal = () => {
    // references are now synced and can be accessed.
    subtitle.style.color = '#f00';
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const data = Object.keys(container.content).map((item) => ({
    label: item,
    value: container.content[item],
  }));

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      maxWidth: '1000px',
      height: '600px',
      borderRadius: '10px',
    },
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      style={{
        margin: 'auto',
        width: '80%',
      }}
      onClick={(event) => openModal(event)}
      onKeyDown={(event) => openModal(event)}
    >
      {/* Start heading */}
      <TextBlock
        key="bubble-graph-heading"
        caseTitle={container.heading}
        cssClass="mt--100 mb--180"
      />
      {/* End heading */}
      <BubbleChart
        width={1200}
        height={1380}
        padding={20} // optional value, number that set the padding between bubbles
        showLegend={false} // optional value, pass false to disable the legend.
        legendFont={{
          family: 'Arial',
          size: 12,
          color: '#000',
          weight: 'bold',
        }}
        valueFont={{
          family: 'Arial',
          size: 12,
          color: '#fff',
          weight: 'bold',
        }}
        labelFont={{
          family: 'Arial',
          size: 16,
          color: '#fff',
          weight: 'bold',
        }}
        data={data}
      />
      {modalText.length !== 0 ? (
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
          appElement={document.getElementById('root') || undefined}
        >
          <h2
            className="theme-gradient"
            ref={(_subtitle) => (subtitle = _subtitle)}
          >
            {modalHeading}
          </h2>
          <div>
            {modalText.length !== 0
              ? modalText.map((item, index) => (
                  <p className="p-5" key={`bubble-text-${index}`}>
                    "{item.text}"
                  </p>
                ))
              : ''}
          </div>
        </Modal>
      ) : (
        ''
      )}
    </div>
  );
};

BubbleGraph.propTypes = {
  container: PropTypes.object,
};

export default BubbleGraph;
