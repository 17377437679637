/* eslint-disable react/jsx-props-no-spreading */
import React, { memo, useState } from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from 'react-icons/fi';
import { isMobileOnly } from 'react-device-detect';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { BlocksRenderer } from '@strapi/blocks-react-renderer';
import PageHelmet from './common/Helmet';
import Header from './common/Header';
import Breadcrumb from './common/Breadcrumb';
import ChapterButtons from './common/ChapterButtons';
import Timeline from './objects/Timeline';
import TextBlock from './objects/TextBlock';
import Image from './objects/Image';
import BrandTwo from './BrandTwo';
import AboutThree from './about/AboutThree';
import FooterTwo from './common/FooterTwo';
import { siteUrl, slickDot } from '../utils/constants';
import { imgSize } from '../utils/funcs';

const NewsLink = ({ newsImage }) => (
  <Link
    to={{ pathname: newsImage.url }}
    target="_blank"
    rel="noopener noreferrer"
  >
    <img
      className="w-100"
      src={
        newsImage.image?.data?.attributes?.formats?.small?.url
          ? siteUrl + newsImage.image.data.attributes.formats.small.url
          : ''
      }
      alt="Blog Images"
    />
  </Link>
);

const NewsLinkItem = ({ newsImage }) => (
  <>
    <div className="thumbnail">
      <NewsLink newsImage={newsImage} />
    </div>
    <div className="content">
      <div className="inner">
        <div className="content_heading">
          <h4 className="title">
            <Link to={{ pathname: newsImage.url }} target="_blank">
              {newsImage?.heading}
            </Link>
          </h4>
          <div className="category_list">
            <Link to={{ pathname: newsImage.url }} target="_blank">
              {newsImage?.text}
            </Link>
          </div>
        </div>
        <div className="content_footer">
          <Link
            to={{ pathname: newsImage.url }}
            target="_blank"
            className="rn-btn btn-opacity"
          >
            Read More
          </Link>
        </div>
      </div>
      <Link
        className="transparent_link"
        to={{ pathname: newsImage.url }}
        target="_blank"
      />
    </div>
  </>
);

const Author = ({ author, backgroundImages, sponsors }) => {
  const backgroundSection = 'About';
  const section = 'Author';
  const [activeChapterIndex, setActiveChapterIndex] = useState(0);
  const backgroundImageAttribute = isMobileOnly ? 'scroll' : 'fixed';

  const setActiveChapter = (containerId) => {
    setActiveChapterIndex(containerId);
  };

  const backgroundImg = backgroundImages?.find(
    (item) => item.attributes?.section === backgroundSection.toLocaleLowerCase()
  );

  return (
    <>
      <PageHelmet pageTitle={section} />
      <Header section={section} />
      <Breadcrumb backgroundSection={backgroundSection} title={section} />
      <div className="about-area ptb--60 bg_color--1">
        <AboutThree
          heading={author?.headingOne}
          superHeading={author?.superHeadingOne}
          text={author?.textOne}
          imageUrl={
            author?.image
              ? siteUrl + author.image.data.attributes.formats.medium.url
              : ''
          }
        />
      </div>
      <div id="bttns" />
      <ChapterButtons
        activeContainer={activeChapterIndex}
        activateContainer={setActiveChapter}
        caseName={author}
      />
      <div className="container mt-xl-5 mb-xl-5">
        <div className="row">
          <div className="col-lg-12">
            <div className="inner-wrapper">
              <div className="inner">
                <BlocksRenderer
                  content={
                    author?.headingTextBlock?.[activeChapterIndex]?.textBlock ||
                    []
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rn-blog-area pt--120 pb--140 bg_color--5">
        <div className="container">
          <div className="row align-items-end">
            <div className="col-lg-12">
              <div className="section-title text-center">
                <span className="subtitle">{author?.superHeadingFour}</span>
                <h3 className="title">{author?.headingFour}</h3>
                <p className="description">{author?.textFour}</p>
              </div>
            </div>
          </div>
          <div className="row mt_sm--30 rn-slick-dot slick-space-gutter--15 slickdot--20 row--0">
            <div className="col-lg-12">
              {author?.news && (
                <Slider {...slickDot}>
                  {author.news.map((newsImage, i) => (
                    <div className="im_box" key={i}>
                      <NewsLinkItem newsImage={newsImage} />
                    </div>
                  ))}
                </Slider>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className="rn-finding-us-area attacment-fixed rn-finding-us ptb--120 bg_color--1 bg_image"
        data-black-overlay="5"
        style={{
          backgroundImage: `url(${imgSize(
            backgroundImg?.attributes?.image?.data,
            isMobileOnly
          )})`,
          backgroundAttachment: backgroundImageAttribute,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="inner">
                <div className="content-wrapper">
                  <div className="content">
                    <h3 className="theme-gradient">{author?.headingThree}</h3>
                    <TextBlock textBlock={author?.textThree} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rn-counterup-area ptb--120 bg_color--5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title text-center">
                <span className="subtitle">{author?.superHeadingTwo}</span>
                <h3 className="title">{author?.headingTwo}</h3>
                <p className="description">{author?.textTwo}</p>
              </div>
            </div>
            <Timeline timeline={author?.timeline || []} />
          </div>
        </div>
      </div>
      {backgroundImages.length !== 0 && true && (
        <Image
          caption={
            backgroundImg?.attributes?.image?.data?.attributes?.caption || ''
          }
          cssClass="mb-dec--40"
          imgType="fullWidth"
          imgUrl={imgSize(
            backgroundImg?.attributes?.image?.data?.attributes || '',
            isMobileOnly
          )}
        />
      )}
      <BrandTwo sponsors={sponsors} />
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      <FooterTwo />
    </>
  );
};

Author.propTypes = {
  author: PropTypes.object,
  backgroundImages: PropTypes.array,
  sponsors: PropTypes.object,
};

NewsLink.propTypes = {
  newsImage: PropTypes.shape({
    url: PropTypes.string,
    image: PropTypes.shape({
      data: PropTypes.shape({
        attributes: PropTypes.shape({
          formats: PropTypes.shape({
            small: PropTypes.shape({
              url: PropTypes.string,
            }),
          }),
        }),
      }),
    }),
  }).isRequired,
};

NewsLinkItem.propTypes = {
  newsImage: PropTypes.shape({
    url: PropTypes.string,
    heading: PropTypes.string,
    text: PropTypes.string,
    image: PropTypes.shape({
      data: PropTypes.shape({
        attributes: PropTypes.shape({
          formats: PropTypes.shape({
            small: PropTypes.shape({
              url: PropTypes.string,
            }),
          }),
        }),
      }),
    }),
  }).isRequired,
};

export default memo(Author);
