import React from 'react';

const TextUrl = ({ tbLinks }) =>
  tbLinks.map((content) => {
    if (content.indexOf(']{') !== -1) {
      const tbLink = content.split('}');

      return tbLink.map((tbContent, tbContentIndex) => {
        if (tbContent.indexOf(']{') !== -1) {
          const hrefText = tbContent.split(']{');

          return (
            <a
              className="theme-gradient"
              href={hrefText[1]}
              key={`tbLinks${tbContentIndex}`}
              target="_blank"
              rel="noreferrer"
            >
              {hrefText[0].replace('[', '')}
            </a>
          );
        }

        return tbLink[1];
      });
    }

    if (content.indexOf('quote=') !== -1 || content.indexOf('*') !== -1) {
      return '';
    }

    return content;
  });

export default TextUrl;
