import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import PropTypes from 'prop-types';
import ImgCaption from './ImgCaption';

const ImgFullWidth = ({ caption, cssClass = '', elementId = '', url }) => (
  <div
    id={elementId}
    className={`rn-page-title-area pt--250 pb--190 bg_image mb--40 ${cssClass}`}
    data-black-overlay="2"
    style={
      url
        ? {
            backgroundImage: `url(${url})`,
            backgroundAttachment: `${isMobileOnly ? 'scroll' : 'fixed'}`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }
        : {}
    }
  >
    <ImgCaption caption={caption} />
  </div>
);
ImgFullWidth.propTypes = {
  caption: PropTypes.string,
  cssClass: PropTypes.string,
  elementId: PropTypes.string,
  url: PropTypes.string,
};

export default ImgFullWidth;
