import React, {
  useCallback,
  useMemo,
  useRef,
  useEffect,
  lazy,
  Suspense,
  memo,
} from 'react';
import { Link } from 'react-router-dom';
import { isMobileOnly } from 'react-device-detect';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {
  Autoplay,
  EffectFade,
  Keyboard,
  Mousewheel,
  Pagination,
} from 'swiper/core';
import { VideoTag } from 'react-video-tag';
import PropTypes from 'prop-types';

import { siteUrl, swiperDelay } from '../utils/constants';
import { imgSize } from '../utils/funcs';

// Import Swiper styles
import 'swiper/swiper.min.css';
import 'swiper/components/effect-fade/effect-fade.min.css';
import 'swiper/components/pagination/pagination.min.css';

// Import lazy components
const Header = lazy(() => import('./common/Header'));
const Helmet = lazy(() => import('./common/Helmet'));

// Initialize Swiper modules
SwiperCore.use([Autoplay, EffectFade, Keyboard, Mousewheel, Pagination]);

// Define cleanup function outside of useEffect
const cleanupClasses = (tags) => {
  for (const tag of tags) {
    if (tag && tag.classList.contains('rootClass')) {
      tag.classList.remove('rootClass');
    }
    if (tag && tag.classList.contains('superClass')) {
      tag.classList.remove('superClass');
    }
  }
};

const Home = ({ home }) => {
  const section = 'Home';
  const htmlRef = useRef(document.documentElement);
  const bodyRef = useRef(document.body);
  const rootRef = useRef(document.getElementById('root'));
  const swiperRef = useRef(null);

  useEffect(() => {
    const rootTag = rootRef.current;
    const currentHtmlRef = htmlRef.current;
    const currentBodyRef = bodyRef.current;

    if (rootTag) {
      rootTag.classList.add('rootClass');
    }
    currentHtmlRef.classList.add('superClass');
    currentBodyRef.classList.add('superClass');

    return () => {
      cleanupClasses([rootTag, currentHtmlRef, currentBodyRef].filter(Boolean));
    };
  }, [rootRef, htmlRef, bodyRef]);

  const sortByOrderAttribute = useCallback((a, b) => {
    const orderA = (a.attributes?.order || '').toUpperCase();
    const orderB = (b.attributes?.order || '').toUpperCase();
    return orderA.localeCompare(orderB);
  }, []);

  const sortedHomeItems = useMemo(() => {
    if (home?.length > 0) {
      return [...home].sort(sortByOrderAttribute);
    }
    return [];
  }, [home, sortByOrderAttribute]);

  const handleLazyLoadError = useCallback((error) => {
    console.error('Error occurred during lazy loading:', error);
  }, []);

  return (
    <>
      <Suspense fallback={<div>Loading...</div>} onError={handleLazyLoadError}>
        <Helmet pageTitle="Home" />
        <Header section={section} />
      </Suspense>
      <Swiper
        ref={swiperRef}
        pagination={{ clickable: true }}
        keyboard={{ enabled: true }}
        autoplay={{ delay: swiperDelay, disableOnInteraction: false }}
        effect="fade"
        className="mySwiper"
        allowSlideNext
        loop
        mousewheel
      >
        {sortedHomeItems.map((item) => (
          <SwiperSlide key={item.id}>
            <div
              className="slide slide-style-2 slider-video-bg d-flex align-items-center justify-content-center"
              data-black-overlay="4"
            >
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-12">
                    <div className="inner pt_sm--40 text-center">
                      {item.attributes?.heading ? (
                        <h1 className="title">{item.attributes.heading}</h1>
                      ) : null}
                      {item.attributes?.text ? (
                        <p className="description">{item.attributes.text}</p>
                      ) : null}
                      {item.attributes?.preamble ? (
                        <span className="theme-color font-700 mt--20">
                          {item.attributes.preamble}
                        </span>
                      ) : null}
                      <Link
                        to={item.attributes?.url || '#'}
                        className="btn-default mt--30 size-md"
                        role="button"
                        tabIndex={0}
                      >
                        <span>Read More</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="video-background">
                {item.attributes?.media?.data?.attributes?.mime?.includes(
                  'video'
                ) ? (
                  <VideoTag
                    autoPlay
                    muted
                    playsInline
                    loop
                    src={`${new URL(
                      item.attributes?.media?.data?.attributes?.url,
                      siteUrl
                    ).toString()}`}
                    controls={false}
                  />
                ) : (
                  <img
                    src={imgSize(item.attributes?.media?.data, isMobileOnly)}
                    alt="case background"
                    className="bg-img"
                  />
                )}
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

Home.propTypes = {
  home: PropTypes.arrayOf(PropTypes.object),
};

export default memo(Home);
