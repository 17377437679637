import React from 'react';
import { VideoTag } from 'react-video-tag';
import { isMobileOnly } from 'react-device-detect';
import PropTypes from 'prop-types';
import { siteUrl } from '../../utils/constants';

const VideoFullWidth = ({ url, elementId = '' }) => (
  <>
    <div id={elementId}>
      <div className="bkg">
        {isMobileOnly ? (
          <VideoTag
            autoPlay="true"
            muted="true"
            playsInline="true"
            loop="true"
            src={`${siteUrl}${url}`}
            poster={`${'/assets/images/bg/bg-image-24.jpg'}`}
          />
        ) : (
          <video className="vid" autoPlay muted loop playsInline>
            <source src={`${siteUrl}${url}`} type="video/mp4" />
          </video>
        )}
      </div>
    </div>
  </>
);

VideoFullWidth.propTypes = {
  elementId: PropTypes.string,
  url: PropTypes.string,
};

export default VideoFullWidth;
